/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/RoofInsulation.glb --types 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Cube001: THREE.Mesh;
    Cube202: THREE.Mesh;
    Cube201: THREE.Mesh;
  };
  materials: {
    ['WOOD 1']: THREE.MeshStandardMaterial;
    ['WOOD 2']: THREE.MeshStandardMaterial;
    ['HIGHLIGHT.004']: THREE.MeshStandardMaterial;
  };
};

export function Model({ active, scale }: { active: boolean; scale: number }) {
  const { nodes, materials } = useGLTF('/models/RoofInsulation.glb') as GLTFResult;
  return (
    <group dispose={null} scale={scale}>
      <mesh
        geometry={nodes.Cube001.geometry}
        material={active ? materials['HIGHLIGHT.004'] : materials['WOOD 1']}
        position={[2.017, 4.935, 0]}
        rotation={[Math.PI / 4, 0, 0]}
      />
      <mesh
        geometry={nodes.Cube202.geometry}
        material={active ? materials['HIGHLIGHT.004'] : materials['WOOD 2']}
        position={[1.029, 4.778, 0]}
        rotation={[Math.PI / 4, 0, 0]}
      />
      <mesh
        geometry={nodes.Cube201.geometry}
        material={active ? materials['HIGHLIGHT.004'] : materials['WOOD 2']}
        position={[1.938, 4.778, 0]}
        rotation={[Math.PI / 4, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload('/models/RoofInsulation.glb');
