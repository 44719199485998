import React, { useLayoutEffect, useRef, useState } from 'react';

interface IAnimatedCounterProps {
  value: number;
  duration?: number;
}

const AnimatedCounter = ({ value, duration = 500 }: IAnimatedCounterProps) => {
  const [count, setCount] = useState<number>(0);
  const previousValueRef = useRef<number>(0);

  useLayoutEffect(() => {
    let startTime: number | null = null;
    let animationFrameId: number;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / duration;

      const easeOutCubic = (t: number): number => 1 - Math.pow(1 - t, 3);
      const easedProgress = easeOutCubic(Math.min(progress, 1));

      const currentCount = Math.round(previousValueRef.current + (value - previousValueRef.current) * easedProgress);

      setCount(currentCount);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setCount(value);
      }
    };

    previousValueRef.current = count;
    animationFrameId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrameId);
  }, [value, count, duration]);

  return <span>{count}</span>;
};

export default AnimatedCounter;
