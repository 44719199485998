import React from 'react';
import { MdArrowRightAlt } from 'react-icons/md';
import AnimatedCounter from './AnimatedCounter';

interface ISavingsCardProps {
  label: string;
  monetarySaving: number;
  co2Saving?: number;
}

const SavingsCard = ({ label, monetarySaving, co2Saving }: ISavingsCardProps) => {
  return (
    <div className="bg-neutral-lighter flex min-h-24 w-96 grow flex-col gap-4 rounded-[29px] p-6 shadow-md">
      <h3 className="text-sm md:text-base">{label}</h3>
      <div className="flex flex-col gap-2">
        <div>
          <p className="text-[#516B75]">Saves:</p>
          <h3 className="text-4xl">£{<AnimatedCounter value={monetarySaving} />}</h3>
        </div>
        {co2Saving && (
          <div className="flex flex-row items-center">
            <MdArrowRightAlt className="rotate-90 text-[#227D72]" size={38} />
            <p>{<AnimatedCounter value={co2Saving} />}kg CO₂</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SavingsCard;
