/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/CavityWallInsulation.glb --types 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Cube016: THREE.Mesh;
    Cube199: THREE.Mesh;
  };
  materials: {
    ['wall.003']: THREE.MeshStandardMaterial;
    ['HIGHLIGHT.006']: THREE.MeshStandardMaterial;
  };
};

export function Model({ active, scale }: { active: boolean; scale: number }) {
  const { nodes, materials } = useGLTF('/models/CavityWallInsulation.glb') as GLTFResult;
  return (
    <group dispose={null} scale={scale}>
      <mesh
        geometry={nodes.Cube016.geometry}
        material={active ? materials['HIGHLIGHT.006'] : materials['wall.003']}
        position={[-0.859, 0.256, 0]}
      />
    </group>
  );
}

useGLTF.preload('/models/CavityWallInsulation.glb');
