import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { AddressResult, PostcodeSearch } from '@epcbuilder/lib/models/properties';
import { getAddressesForPostcode } from '@epcbuilder/lib/network/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RegisterModal } from '../get-started';

const postcodeSearchSchema = yup.object().shape({
  postcode: yup.string().required().matches(POSTCODE_REGEX, 'Please enter a valid postcode'),
});

const GetStarted = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [results, setResults] = useState<AddressResult[]>([]);

  const defaultValues: PostcodeSearch = {
    postcode: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    getValues,
  } = useForm<PostcodeSearch>({
    defaultValues,
    resolver: yupResolver(postcodeSearchSchema),
  });

  const onSubmit: SubmitHandler<PostcodeSearch> = useCallback(
    async (data: PostcodeSearch) => {
      try {
        const response = await getAddressesForPostcode({ postcode: data.postcode.trim() });
        setResults(response);
        setShowModal(true);
      } catch (error: unknown) {
        const { errors, detail } = error as AxiosErrorData;
        handleFormErrors<PostcodeSearch>(setError, errors);

        switch (detail) {
          case 'No addresses could be found':
          case 'Failed getting address':
            toast.error('There was an error, please check your postcode and try again');
            break;
          default:
            handleUnknownDetail(error);
            break;
        }
      }
    },
    [setError]
  );

  return (
    <>
      <form
        id="postcode-search-form"
        className="relative flex w-full flex-col items-center justify-center gap-2 sm:flex-row"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-[90%] max-w-[328px] sm:w-full">
          <TextInput
            {...register('postcode')}
            id="Postcode"
            name="postcode"
            title="Your postcode"
            placeholder="Enter postcode..."
            error={errors.postcode?.message}
            className="h-12 w-full rounded-3xl"
          />
        </div>
        <div className="w-[90%] max-w-[328px] sm:w-full">
          <NewButton
            className="font-header flex h-8 w-full items-center justify-center rounded-[12px] px-3 text-xs uppercase shadow hover:opacity-80 lg:h-10 lg:rounded-[30px] lg:text-base"
            text="Get your epc rating"
            loading={isSubmitting}
            type="submit"
            id="postcode-search"
            sizeClasses="h-12"
          />
        </div>
      </form>

      {showModal && (
        <RegisterModal
          postcode={getValues('postcode')}
          initialResults={results}
          onClose={() => setShowModal(false)}
          updateInitialResults={onSubmit}
        />
      )}
    </>
  );
};

export default GetStarted;
