import React from 'react';
import { Helmet } from 'react-helmet';
import InstallMeasuresPage from '@/components/install-measures/InstallMeasuresPage';
import Layout from '@/components/Layout';

const InstallMeasures = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Terms of Service">
        <InstallMeasuresPage />
      </Layout>
    </>
  );
};

export default InstallMeasures;
