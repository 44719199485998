import React, { useState } from 'react';
import PageSection from '../PageSection';
import bungalow from './property-type-icons/bungalow';
import bungalowHighlighted from './property-type-icons/bungalowHighlighted';
import detached from './property-type-icons/detached';
import detachedHighlighted from './property-type-icons/detachedHighlighted';
import flat from './property-type-icons/flat';
import flatHighlighted from './property-type-icons/flatHighlighted';
import midTerrace from './property-type-icons/midTerrace';
import midTerraceHighlighted from './property-type-icons/midTerraceHighlighted';
import semiDetached from './property-type-icons/semiDetached';
import semiDetachedHighlighted from './property-type-icons/semiDetachedHighlighted';
import PropertyTypeButton, { IPropertyType, PropertyType } from './PropertyTypeButton';
import SavingsCard from './SavingsCard';

const propertyTypes: Array<IPropertyType> = [
  {
    propertyType: PropertyType.Detached,
    label: 'Detached',
    svg: detached,
    highlightedSvg: detachedHighlighted,
  },
  {
    propertyType: PropertyType.SemiDetached,
    label: 'Semi-detached or End-terrace',
    svg: semiDetached,
    highlightedSvg: semiDetachedHighlighted,
  },
  {
    propertyType: PropertyType.MidTerrace,
    label: 'Mid-terrace',
    svg: midTerrace,
    highlightedSvg: midTerraceHighlighted,
  },
  {
    propertyType: PropertyType.Bungalow,
    label: 'Bungalow',
    svg: bungalow,
    highlightedSvg: bungalowHighlighted,
  },
  {
    propertyType: PropertyType.Flat,
    label: 'Flat',
    svg: flat,
    highlightedSvg: flatHighlighted,
  },
];

interface IImprovementSavings {
  monetarySaving: number;
  co2Saving?: number;
}

interface IImprovementItemSavings {
  label: string;
  [PropertyType.Detached]?: IImprovementSavings;
  [PropertyType.SemiDetached]?: IImprovementSavings;
  [PropertyType.MidTerrace]?: IImprovementSavings;
  [PropertyType.Bungalow]?: IImprovementSavings;
  [PropertyType.Flat]?: IImprovementSavings;
}

// add co2Savings here!!!
const improvementItemSavings: Array<IImprovementItemSavings> = [
  {
    label: 'External Or Internal Wall Insulation',
    [PropertyType.Detached]: { monetarySaving: 550, co2Saving: 1500 },
    [PropertyType.SemiDetached]: { monetarySaving: 320, co2Saving: 900 },
    [PropertyType.MidTerrace]: { monetarySaving: 190, co2Saving: 500 },
    [PropertyType.Bungalow]: { monetarySaving: 240, co2Saving: 650 },
    [PropertyType.Flat]: { monetarySaving: 150, co2Saving: 410 },
  },
  {
    label: 'Cavity Wall Insulation',
    [PropertyType.Detached]: { monetarySaving: 410, co2Saving: 1100 },
    [PropertyType.SemiDetached]: { monetarySaving: 240, co2Saving: 650 },
    [PropertyType.MidTerrace]: { monetarySaving: 140, co2Saving: 380 },
    [PropertyType.Bungalow]: { monetarySaving: 180, co2Saving: 490 },
    [PropertyType.Flat]: { monetarySaving: 110, co2Saving: 310 },
  },
  {
    label: 'Loft Insulation',
    [PropertyType.Detached]: { monetarySaving: 380, co2Saving: 1000 },
    [PropertyType.SemiDetached]: { monetarySaving: 230, co2Saving: 600 },
    [PropertyType.MidTerrace]: { monetarySaving: 200, co2Saving: 550 },
    [PropertyType.Bungalow]: { monetarySaving: 370, co2Saving: 1000 },
  },
  {
    label: 'Solar PV',
    [PropertyType.Detached]: { monetarySaving: 675, co2Saving: 860 },
    [PropertyType.SemiDetached]: { monetarySaving: 465, co2Saving: 580 },
    [PropertyType.MidTerrace]: { monetarySaving: 245, co2Saving: 290 },
    [PropertyType.Bungalow]: { monetarySaving: 665, co2Saving: 860 },
  },
  {
    label: 'New Boiler',
    [PropertyType.Detached]: { monetarySaving: 490, co2Saving: 1241 },
    [PropertyType.SemiDetached]: { monetarySaving: 320, co2Saving: 1101 },
    [PropertyType.MidTerrace]: { monetarySaving: 270, co2Saving: 1101 },
    [PropertyType.Bungalow]: { monetarySaving: 290, co2Saving: 1101 },
    [PropertyType.Flat]: { monetarySaving: 120, co2Saving: 823 },
  },
];

const YourSavingsPage = () => {
  const [selectedPropertyType, setSelectedPropertyType] = useState<PropertyType>(PropertyType.Detached);

  return (
    <div className="flex w-full flex-col items-center gap-9 overflow-hidden  md:gap-12">
      <div
        id="your-savings-intro"
        className="xl:mx-[max(1.5rem, auto)] mx-4  mt-7 flex flex-col gap-4 sm:mx-8 sm:mt-6 md:mx-6 md:mt-12 md:gap-6 xl:w-11/12 xl:max-w-screen-2xl"
      >
        <h2 className="text-dark text-md text-2xl font-bold leading-tight md:text-[48px] xl:text-[64px]">
          Your Savings
        </h2>
        <p className="text-base leading-7 md:text-xl md:leading-8 xl:text-2xl xl:leading-9">
          Small changes can make a big difference. Discover how energy-saving measures like solar panels or wall
          insulation can reduce your bills and carbon footprint. Select your property type to see tailored insights into
          potential cost savings and CO2 reductions. Together, we can make your home greener and more efficient while
          lowering your expenses.
        </p>
      </div>
      <PageSection
        title="Choose Your Property Type:"
        containerClassName="w-full xl:items-center items-start"
        headerContainerClassName="xl:mx-[max(1.5rem, auto)] mx-4 flex flex-col gap-4 sm:mx-8 md:mx-6 md:gap-6 xl:w-11/12 xl:max-w-screen-2xl"
        childContainerClassName="w-full xl:w-11/12 xl:max-w-screen-2xl"
      >
        <div className="hide-scrollbar flex max-w-full flex-row justify-evenly gap-4 overflow-x-auto p-4 xl:max-w-screen-2xl">
          {propertyTypes.map((propertyType, index) => (
            <PropertyTypeButton
              key={`property-type-${index}`}
              propertyType={propertyType.propertyType}
              label={propertyType.label}
              svg={propertyType.svg}
              highlightedSvg={propertyType.highlightedSvg}
              isSelected={propertyType.propertyType === selectedPropertyType}
              onClick={() => setSelectedPropertyType(propertyType.propertyType)}
            />
          ))}
        </div>
      </PageSection>
      <PageSection
        title="Your Yearly Savings:"
        containerClassName="w-full xl:items-center items-start mb-7 sm:mb-6 md:mb-12"
        headerContainerClassName="xl:mx-[max(1.5rem, auto)] mx-4 flex flex-col gap-4 sm:mx-8 md:mx-6 md:gap-6 xl:w-11/12 xl:max-w-screen-2xl"
        childContainerClassName="w-full xl:w-11/12 xl:max-w-screen-2xl xl:mx-[max(1.5rem, auto)]"
      >
        <div className="relative">
          <div className="z-10 mx-4 flex flex-wrap justify-evenly gap-4 sm:mx-8 md:mx-6 md:gap-6 xl:max-w-screen-2xl">
            {improvementItemSavings.map(
              (item, index) =>
                item[selectedPropertyType] && (
                  <SavingsCard
                    key={`savings-card-${index}`}
                    label={item.label}
                    monetarySaving={item[selectedPropertyType]?.monetarySaving || 0}
                    co2Saving={item[selectedPropertyType]?.co2Saving}
                  />
                )
            )}
          </div>

          {/* start of background divs */}
          <div className="absolute right-[-150px] top-[100px] z-[-1] h-[378.41px] w-[517.005px] shrink-0 rotate-[80.029deg] rounded-[517.005px] bg-[#02695C] opacity-20 blur-xl sm:left-[-200px] sm:right-[unset] sm:top-[50px] xl:left-[0px]" />
          <div className="absolute left-[-50px] top-[420px] z-[-1] h-[236.949px] w-[323.732px] shrink-0 rotate-[80.029deg] rounded-[323.732px] bg-[#F4A261] opacity-20 blur-xl sm:left-[unset] sm:right-[50px] sm:top-[0px] xl:right-[350px]" />
          <div className="absolute left-[-30px] top-[650px] z-[-1] h-[242.253px] w-[330.98px] shrink-0 rotate-[80.029deg] rounded-[330.98px] bg-[#2A9C8E] opacity-20 blur-xl sm:left-[325px] sm:top-[150px] xl:left-[525px]" />
          <div className="absolute right-[-150px] top-[900px] z-[-1] h-[239.302px] w-[340.725px] shrink-0 rotate-[80.029deg] rounded-[340.725px] bg-[#E9C469] opacity-20 blur-xl sm:left-[175px] sm:right-[unset] sm:top-[250px] xl:left-[375px]" />
          <div className="absolute right-[-50px] top-[1050px] z-[-1] h-[371.773px] w-[529.341px] shrink-0 rotate-[80.029deg] rounded-[529.341px] bg-[#E76F51] opacity-20 blur-xl sm:right-[-350px] sm:top-[150px] xl:right-[-50px]" />
          {/* end of background divs */}
        </div>
      </PageSection>
    </div>
  );
};

export default YourSavingsPage;
