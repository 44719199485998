import React from 'react';

export default (
  <svg width="140" height="110" viewBox="0 0 140 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.6952 29.0898H16.139C15.6608 29.0898 15.2734 29.4772 15.2734 29.9554V42.4754C15.2734 42.9536 15.6608 43.3409 16.139 43.3409H37.6952C38.1734 43.3409 38.5607 42.9536 38.5607 42.4754V29.9554C38.5607 29.4772 38.1734 29.0898 37.6952 29.0898ZM22.4574 41.6098H17.0045V30.8209H22.4574V41.6098ZM36.8296 41.6098H31.3746V30.8209H36.8296V41.6098Z"
      fill="#F4A261"
    />
    <path
      d="M22.4249 55.0264H16.139C15.661 55.0264 15.2734 55.4139 15.2734 55.8919V67.5204C15.2734 67.9984 15.661 68.3859 16.139 68.3859H22.4249C22.903 68.3859 23.2905 67.9984 23.2905 67.5204V55.8919C23.2905 55.4139 22.903 55.0264 22.4249 55.0264Z"
      fill="#E9C469"
    />
    <path
      d="M16.139 100.585C16.6172 100.585 17.0045 100.197 17.0045 99.7191V95.2464C17.0045 94.7682 16.6172 94.3809 16.139 94.3809C15.6608 94.3809 15.2734 94.7682 15.2734 95.2464V99.7191C15.2734 100.197 15.6608 100.585 16.139 100.585Z"
      fill="#E76F51"
    />
    <path
      d="M80.8048 55.0264H74.5189C74.0408 55.0264 73.6533 55.4139 73.6533 55.8919V67.5204C73.6533 67.9984 74.0408 68.3859 74.5189 68.3859H80.8048C81.2828 68.3859 81.6704 67.9984 81.6704 67.5204V55.8919C81.6704 55.4139 81.2828 55.0264 80.8048 55.0264Z"
      fill="#E9C469"
    />
    <path
      d="M96.7238 30.2777L70.6754 0.762822C70.511 0.576732 70.2751 0.470703 70.0263 0.470703C69.7774 0.470703 69.5416 0.576732 69.3771 0.762822L48.4701 24.4526L27.563 0.762822C27.3986 0.576732 27.1627 0.470703 26.9139 0.470703C26.665 0.470703 26.4292 0.576732 26.2647 0.762822L0.216376 30.2777C-0.00866327 30.533 -0.0627593 30.8965 0.0757266 31.206C0.216376 31.5154 0.523642 31.7166 0.865529 31.7166H4.48997V108.663C4.48997 109.141 4.87729 109.528 5.3555 109.528H91.5846C92.0629 109.528 92.4502 109.141 92.4502 108.663V31.7166H96.0746C96.4143 31.7166 96.7238 31.5175 96.8644 31.206C97.0051 30.8965 96.9488 30.533 96.7238 30.2777ZM10.7132 29.0508L26.9139 10.6949L43.1146 29.0529V72.9184H10.7132V29.0529V29.0508ZM18.8622 107.797H6.22104V88.0631H18.8622V107.797ZM47.6045 107.797H20.5933V79.12H47.6045V107.797ZM47.6045 77.3889H6.22104V31.7166H7.96943C8.21827 31.7166 8.45413 31.6106 8.61858 31.4245L8.9821 31.0134V73.7839C8.9821 74.2621 9.36943 74.6495 9.84764 74.6495H43.9801C44.4583 74.6495 44.8456 74.2621 44.8456 73.7839V31.7188H47.6045V77.3911V77.3889ZM53.8256 29.0529L70.0263 10.6949L86.227 29.0529V72.9184H53.8256V29.0551V29.0529ZM76.3469 107.797H49.3356V79.12H76.3469V107.797ZM90.7191 107.797H78.0779V88.0631H90.7191V107.797ZM90.7191 77.3889H49.3356V31.7166H52.0945V73.7818C52.0945 74.26 52.4818 74.6473 52.96 74.6473H87.0925C87.5707 74.6473 87.958 74.26 87.958 73.7818V31.0112L88.3216 31.4223C88.486 31.6084 88.7219 31.7145 88.9707 31.7145H90.7191V77.3867V77.3889Z"
      fill="#2A9C8E"
    />
    <path
      d="M80.8045 100.585C81.2827 100.585 81.67 100.197 81.67 99.7191V95.2464C81.67 94.7682 81.2827 94.3809 80.8045 94.3809C80.3263 94.3809 79.939 94.7682 79.939 95.2464V99.7191C79.939 100.197 80.3263 100.585 80.8045 100.585Z"
      fill="#E76F51"
    />
    <path
      d="M80.4228 29H58.8666C58.3884 29 58.0011 29.3873 58.0011 29.8655V42.3855C58.0011 42.8638 58.3884 43.2511 58.8666 43.2511H80.4228C80.901 43.2511 81.2884 42.8638 81.2884 42.3855V29.8655C81.2884 29.3873 80.901 29 80.4228 29ZM65.1851 41.52H59.7322V30.7311H65.1851V41.52ZM79.5573 41.52H74.1022V30.7311H79.5573V41.52Z"
      fill="#F4A261"
    />
    <path
      d="M65.4885 55.0264H59.2026C58.7246 55.0264 58.337 55.4139 58.337 55.8919V67.5204C58.337 67.9984 58.7246 68.3859 59.2026 68.3859H65.4885C65.9666 68.3859 66.3541 67.9984 66.3541 67.5204V55.8919C66.3541 55.4139 65.9666 55.0264 65.4885 55.0264Z"
      fill="#E9C469"
    />
    <path
      d="M123.865 29.0898H102.309C101.831 29.0898 101.443 29.4772 101.443 29.9554V42.4754C101.443 42.9536 101.831 43.3409 102.309 43.3409H123.865C124.343 43.3409 124.731 42.9536 124.731 42.4754V29.9554C124.731 29.4772 124.343 29.0898 123.865 29.0898ZM108.629 41.6098H103.174V30.8209H108.629V41.6098ZM115.816 41.6098H110.361V30.8209H115.816V41.6098Z"
      fill="#F4A261"
    />
    <path
      d="M123.868 55.0264H117.582C117.104 55.0264 116.717 55.4139 116.717 55.8919V67.5204C116.717 67.9984 117.104 68.3859 117.582 68.3859H123.868C124.346 68.3859 124.734 67.9984 124.734 67.5204V55.8919C124.734 55.4139 124.346 55.0264 123.868 55.0264Z"
      fill="#E9C469"
    />
    <path
      d="M139.787 30.2777L113.739 0.762822C113.575 0.576732 113.339 0.470703 113.09 0.470703C112.841 0.470703 112.605 0.576732 112.441 0.762822L91.5337 24.4526L70.6266 0.762822C70.4622 0.576732 70.2263 0.470703 69.9775 0.470703C69.7286 0.470703 69.4928 0.576732 69.3283 0.762822L43.28 30.2777C43.0549 30.533 43.0008 30.8965 43.1393 31.206C43.28 31.5154 43.5872 31.7166 43.9291 31.7166H47.5536V108.663C47.5536 109.141 47.9409 109.528 48.4191 109.528H134.648C135.126 109.528 135.514 109.141 135.514 108.663V31.7166H139.138C139.478 31.7166 139.787 31.5175 139.928 31.206C140.069 30.8965 140.012 30.533 139.787 30.2777ZM53.7768 29.0508L69.9775 10.6949L86.1782 29.0529V72.9184H53.7768V29.0529V29.0508ZM61.9258 107.797H49.2846V79.12C49.2846 79.12 56.9891 79.12 61.9258 79.12C61.9258 90.3192 61.9258 107.797 61.9258 107.797ZM90.6681 107.797H61.9258C61.9258 107.797 61.9258 90.3192 61.9258 79.12C73.1504 79.12 90.6681 79.12 90.6681 79.12V107.797ZM90.6681 77.3889H49.2846V31.7166H51.033C51.2819 31.7166 51.5177 31.6106 51.6822 31.4245L52.0457 31.0134V73.7839C52.0457 74.2621 52.433 74.6495 52.9112 74.6495H87.0437C87.5219 74.6495 87.9092 74.2621 87.9092 73.7839V31.7188H90.6681V77.3911V77.3889ZM96.8892 29.0529L113.09 10.6949L129.291 29.0529V72.9184H96.8892V29.0551V29.0529ZM119.41 107.797H92.3992V79.12H119.41V107.797ZM133.783 107.797H121.142V88.0631H133.783V107.797ZM133.783 77.3889H92.3992V31.7166H95.1581V73.7818C95.1581 74.26 95.5454 74.6473 96.0236 74.6473H130.156C130.634 74.6473 131.022 74.26 131.022 73.7818V31.0112L131.385 31.4223C131.55 31.6084 131.785 31.7145 132.034 31.7145H133.783V77.3867V77.3889Z"
      fill="#2A9C8E"
    />
    <path
      d="M123.868 100.585C124.346 100.585 124.734 100.197 124.734 99.7191V95.2464C124.734 94.7682 124.346 94.3809 123.868 94.3809C123.39 94.3809 123.002 94.7682 123.002 95.2464V99.7191C123.002 100.197 123.39 100.585 123.868 100.585Z"
      fill="#E76F51"
    />
    <defs>
      <clipPath id="clip0_2988_3568">
        <rect width="96.9401" height="109.058" fill="white" transform="translate(0 0.470703)" />
      </clipPath>
      <clipPath id="clip1_2988_3568">
        <rect width="96.9401" height="109.058" fill="white" transform="translate(43.0598 0.470703)" />
      </clipPath>
    </defs>
  </svg>
);
