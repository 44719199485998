import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Image } from '@epcbuilder/lib/components';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { GetStarted } from '@/components/get-started';
import WavyLines from '@/components/wavy-lines';

enum Stages {
  ENTER_POSTCODE = 1,
  ONSITE_ASSESSMENT = 2,
  IMPROVEMENT_PLAN = 3,
  INSTALL_IMPROVEMENTS = 4,
  SAVINGS_BEGIN = 5,
}

type Stage = {
  id: number;
  label: string;
  content: ReactNode;
  image: string;
};

const stages: Stage[] = [
  {
    id: Stages.ENTER_POSTCODE,
    label: 'Enter your postcode',
    content: (
      <p className="w-full max-w-[150px] font-sans text-sm lg:max-w-none lg:text-base">
        Provide us with your postcode to learn more about your property's energy performance. Add a few extra details to
        find out if the home is eligible for free improvements.
      </p>
    ),
    image: '/images/howdoesitwork/enter-postcode.png',
  },
  {
    id: Stages.ONSITE_ASSESSMENT,
    label: 'On-site assessment',
    content: (
      <div className="flex flex-col gap-2">
        <p className="w-full max-w-[150px] font-sans text-sm lg:max-w-none lg:text-base">
          One of our retrofit assessors will visit the property and conduct an in-depth survey of its current and
          potential energy efficiency.
        </p>
        <p className="w-full max-w-[150px] font-sans text-sm lg:max-w-none lg:text-base">
          Available for £195 (inc VAT) which will be refunded if you choose to proceed with us for an installation.
        </p>
      </div>
    ),
    image: '/images/howdoesitwork/onsite-assessment.png',
  },
  {
    id: Stages.IMPROVEMENT_PLAN,
    label: 'Improvement plan',
    content: (
      <p className="w-full max-w-[150px] font-sans text-sm lg:max-w-none lg:text-base">
        You'll receive a detailed plan including home improvement recommendations a breakdown of costs, along with any
        grants the property is eligible for.
      </p>
    ),
    image: '/images/howdoesitwork/improvement-plan.png',
  },
  {
    id: Stages.INSTALL_IMPROVEMENTS,
    label: 'Install improvements',
    content: (
      <p className="w-full max-w-[150px] font-sans text-sm lg:max-w-none lg:text-base">
        You can then choose to use our skilled in-house team to install the recommended improvements or choose your own
        installers.
      </p>
    ),
    image: '/images/howdoesitwork/install-improvements.png',
  },
  {
    id: Stages.SAVINGS_BEGIN,
    label: 'Energy savings begin',
    content: (
      <p className="w-full max-w-[150px] font-sans text-sm lg:max-w-none lg:text-base">
        After installation, you will soon notice the positive impacts on your property and your bills! We will also
        lodge your new and improved EPC rating.
      </p>
    ),
    image: '/images/howdoesitwork/savings-begin.png',
  },
];

const HowDoesEpcBuilderWork = () => {
  const [active, setActive] = useState<Stages>(Stages.ENTER_POSTCODE);
  const [isScrolling, setIsScrolling] = useState<boolean>(true);
  const howItWorksInterval = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    return () => clearInterval(howItWorksInterval.current);
  }, []);

  useEffect(() => {
    if (isScrolling) {
      howItWorksInterval.current = setInterval(() => setActive((active) => (active % 5) + 1), 5000);
    } else {
      clearInterval(howItWorksInterval.current);
      howItWorksInterval.current = undefined;
    }
  }, [isScrolling]);

  const [emblaRef] = useEmblaCarousel(
    {
      loop: true,
      slidesToScroll: 1,
      duration: 8000,
      breakpoints: {
        '(min-width: 1024px)': { slidesToScroll: 2 },
      },
    },
    [Autoplay({ delay: 0, rootNode: (emblaRoot: HTMLElement) => emblaRoot.parentElement, stopOnInteraction: false })]
  );

  const activeStage = stages.find((stage) => stage.id === active);

  return (
    <div className="relative">
      <div className="pointer-events-none absolute left-0 top-1/3 -z-10 flex w-full -translate-y-1/3 flex-row lg:top-16 lg:-translate-y-0">
        <WavyLines />
      </div>
      <div className="relative mx-auto flex max-w-sm flex-col items-center lg:max-w-none">
        <div className="relative flex flex-col px-4 lg:mt-16 lg:flex-row-reverse xl:gap-16">
          <div className="flex flex-row gap-2 pl-8 lg:flex-col-reverse lg:justify-center">
            <div className="from-primary lg:bg-primary h-48 w-1 bg-gradient-to-t to-transparent lg:h-1 lg:w-44" />
            <h1 className="font-header mt-8 max-w-[280px] text-4xl font-black uppercase lg:mt-0 lg:max-w-xs lg:text-7xl">
              How does epc builder work?
            </h1>
          </div>
          <div className="border-primary flex h-64 w-full flex-row overflow-hidden rounded-[20px] border-2 lg:h-[380px] lg:w-[620px] lg:border-0 lg:shadow-[rgba(0,0,0,0.1)_0px_4px_21px_0px] xl:w-[760px]">
            <div className="flex h-full flex-col">
              {stages.map((stage) => (
                <button
                  type="button"
                  key={stage.id}
                  className={`bg-light relative z-10 flex w-14 flex-1 cursor-pointer items-center justify-center lg:w-[300px] lg:justify-start lg:gap-6 lg:px-6 xl:w-96 ${
                    active !== stage.id && 'shadow-[rgba(42,156,142,0.6)_inset_0px_5px_10px_0px]'
                  }`}
                  onClick={() => {
                    setActive(stage.id);
                    setIsScrolling(false);
                  }}
                  onMouseEnter={() => {
                    setActive(stage.id);
                    setIsScrolling(false);
                  }}
                >
                  {active !== stage.id && (
                    <div className="bg-neutral/70 absolute inset-0 z-10 shadow-[rgba(38,70,83,0.1)_inset_-4px_0px_5px_0px]" />
                  )}
                  <div className="relative size-6 lg:size-10">
                    <div
                      className={`absolute -inset-0.5 -z-10 rounded-full bg-gradient-to-b lg:-inset-1 ${
                        active === stage.id
                          ? 'from-primary-dark via-primary via-secondary-light via-secondary to-secondary-dark'
                          : 'bg-primary'
                      }`}
                    />
                    <p className="text-primary font-header bg-light flex size-6 items-center justify-center rounded-full text-xs font-black lg:size-10 lg:text-xl">
                      {stage.id}
                    </p>
                  </div>
                  <div className="hidden text-lg font-black uppercase lg:flex">{stage.label}</div>
                </button>
              ))}
            </div>
            <div className="bg-light relative size-full">
              {activeStage && (
                <div className="flex h-full flex-col justify-center gap-3 p-4 lg:justify-start lg:px-8 lg:py-6">
                  <h1 className="font-header text-base font-extrabold uppercase lg:text-lg">{activeStage.label}</h1>
                  <div className="flex flex-row items-center lg:flex-col lg:gap-4">
                    {activeStage.content}
                    <div className="relative size-28 xl:size-40">
                      <Image src={activeStage.image} alt="" className="object-contain" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-light relative flex flex-col gap-6 px-4 pb-16 lg:mt-32  lg:w-full lg:flex-row lg:items-center lg:justify-center lg:py-2 lg:shadow-[rgba(42,156,142,0.7)_0px_0px_50px_0px] xl:gap-32">
          <div className="relative mr-12 mt-8 flex w-full max-w-[280px] self-end lg:mr-0 lg:mt-0 lg:max-w-[420px] xl:max-w-[500px]">
            <div className="to-light via-light absolute -left-2 -top-2 z-20 h-24 w-16 bg-gradient-to-l from-transparent lg:h-52" />
            <div className="to-light via-light absolute -right-2 -top-2 z-20 h-24 w-16 bg-gradient-to-r from-transparent lg:h-52" />
            <div className="flex h-20 flex-row items-center overflow-hidden lg:h-48" ref={emblaRef}>
              <div className="flex flex-row">
                {Array.from({ length: 8 }, (_, index) => index + 1).map((index) => (
                  <div key={`slide-${index}`} className="mr-6 flex-[0_0_28%] lg:flex-[0_0_33%] xl:flex-[0_0_26%]">
                    <Image src={`/images/howdoesitwork/carousel/${index}.png`} height={256} width={256} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="font-header mr-12 max-w-[280px] self-end text-right text-4xl font-black uppercase lg:mr-0 lg:max-w-none lg:text-3xl lg:font-bold lg:italic">
              Are you ready to get started?
            </h1>
            <div className="relative z-10 w-full">
              <div
                style={{ transform: 'translate3d(0,0,0)' }}
                className="bg-primary/40 absolute -inset-2 blur-md lg:hidden"
              />
              <GetStarted inline={true} />
            </div>
          </div>
          <div className="from-primary to-primary/10 absolute right-12 h-96 w-1 bg-gradient-to-b lg:hidden" />
          <div className="absolute inset-x-0 -bottom-1 h-20 overflow-hidden">
            <div className="absolute bottom-0 right-0 lg:hidden">
              <div className="from-primary/10 absolute bottom-6 right-12 h-10 w-1 bg-gradient-to-b to-[#264653]" />
              <div className="absolute -bottom-8 right-[26px] size-12 rotate-45 rounded-[0%_50%_100%_50%] bg-[#264653]" />
              <div className="bg-light absolute bottom-0 right-[0px] size-12 rotate-45 rounded-full" />
              <div className="bg-light absolute bottom-0 right-[52px] size-12 rotate-45 rounded-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowDoesEpcBuilderWork;
