import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, TextAreaInput, TextInput } from '@epcbuilder/lib/components';
import { EMAIL_REGEX } from '@epcbuilder/lib/utils';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ContactUsFormData } from '@/models/generic';
import { postContactUs } from '@/network/contact';

const contactUsSchema = yup.object().shape({
  name: yup.string().required('Name must not be empty'),
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone is not a valid UK phone number').required('Phone must not be empty'),
  message: yup.string().required('A message is required'),
});

export const ContactModal = ({ onClose }: { onClose: () => void }) => {
  const defaultValues: ContactUsFormData = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactUsFormData>({
    defaultValues,
    resolver: yupResolver(contactUsSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ContactUsFormData> = async (data) => {
    try {
      await postContactUs(data);
      toast.success('Thank you for your message. A member of our team will be in touch.');
      onClose();
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal onClose={onClose} id="contact-us-modal">
      <div>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <p className="text-center text-lg font-bold">Contact us</p>
          <TextInput
            {...register('name')}
            id="name"
            name="name"
            title="Your name"
            placeholder="Your name"
            error={errors.name?.message}
          />
          <TextInput
            {...register('email')}
            id="email"
            name="email"
            title="Your email"
            placeholder="Your email"
            error={errors.email?.message}
          />
          <TextInput
            {...register('phone')}
            id="phone"
            name="phone"
            title="Your phone number"
            placeholder="Phone"
            error={errors.phone?.message}
          />
          <TextAreaInput
            {...register('message')}
            id="message"
            name="message"
            title="Your message"
            placeholder="Enter your message here"
            error={errors.message?.message}
            rows={3}
          />
          <Button loading={isSubmitting} type="submit">
            Send
          </Button>
        </form>
      </div>
    </Modal>
  );
};
