import React, { ReactNode } from 'react';

export enum PropertyType {
  Detached,
  SemiDetached,
  MidTerrace,
  Bungalow,
  Flat,
}

export interface IPropertyType {
  propertyType: PropertyType;
  label: string;
  svg: ReactNode;
  highlightedSvg: ReactNode;
}

interface IPropertyTypeButtonProps extends IPropertyType {
  isSelected: boolean;
  onClick: () => void;
}

const PropertyTypeButton = ({ label, svg, highlightedSvg, isSelected, onClick }: IPropertyTypeButtonProps) => {
  return (
    <button onClick={onClick} className="flex w-min flex-col items-center justify-start gap-2">
      <div
        className={`propertytype-icon-gradientborder duration-300 hover:shadow-[0px_0px_10px_0px_rgba(42,156,142,0.64)] ${isSelected ? 'shadow-[0px_0px_10px_0px_rgba(42,156,142,0.64)]' : ''}`}
      >
        <div className="relative flex size-2/3 items-center justify-center rounded-full bg-[#fff] p-[75px] lg:p-[100px]">
          <div
            className={`absolute flex size-2/3 items-center justify-center duration-300 ${isSelected ? 'scale-105 opacity-0' : 'opacity-100'}`}
          >
            {svg}
          </div>
          <div
            className={`absolute flex size-2/3 items-center justify-center duration-300 ${isSelected ? 'scale-105 opacity-100' : ' opacity-0'}`}
          >
            {highlightedSvg}
          </div>
        </div>
      </div>
      <h3 className={`w-2/3 font-sans text-lg duration-300 ${isSelected ? 'font-bold' : 'font-semibold'}`}>{label}</h3>
    </button>
  );
};

export default PropertyTypeButton;
