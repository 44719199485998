import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loading, SensitiveTextInput, TextInput } from '@epcbuilder/lib/components';
import useReferrerSourceCookie from '@epcbuilder/lib/hooks/useReferrerSourceCookie';
import { CreateAddress } from '@epcbuilder/lib/models/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { EMAIL_REGEX, PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormStage, RegisterWithAddress } from '@/models/generic';
import { postRegisterWithAddress } from '@/network/register';

const registerSchema = yup.object().shape({
  email: yup.string().required('Email must not be empty').matches(EMAIL_REGEX, 'Email is not a valid email address'),
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
  phone: yup.string().required('Phone must not be empty').matches(PHONE_REGEX, 'Phone is not a valid UK phone number'),
  password: yup.string().required('Password must not be empty').min(6, 'Password must be at least 6 characters long'),
  confirmPassword: yup
    .string()
    .required('Confirm Password must not be empty')
    .oneOf([yup.ref('password'), ''], 'Confirm Password must match Password'),
  addressLine1: yup.string().required('Address Line 1 must not be empty'),
  addressLine2: yup.string().optional(),
  city: yup.string().required('City must not be empty'),
  postcode: yup.string().required().matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
  bypassEPC: yup.boolean().optional(),
  ownerStatusId: yup.number().required().min(1, 'Owner Status must not be empty'),
});

const UserStage = ({
  onClose,
  address,
  setFormStage,
}: {
  onClose: () => void;
  address: CreateAddress;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const refSrcValue = useReferrerSourceCookie();

  const defaultValues: RegisterWithAddress = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: '',
    referrerSource: refSrcValue,
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    postcode: address.postcode,
    bypassEPC: address.bypassEPC,
    ownerStatusId: address.ownerStatusId,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<RegisterWithAddress>({
    defaultValues,
    resolver: yupResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<RegisterWithAddress> = async (data: RegisterWithAddress) => {
    try {
      await postRegisterWithAddress({
        email: data.email.trim(),
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone.trim(),
        password: data.password,
        confirmPassword: data.confirmPassword,
        referrerSource: data.referrerSource,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        postcode: data.postcode.trim(),
        bypassEPC: data.bypassEPC,
        ownerStatusId: data.ownerStatusId,
      });
      toast.success('Registration successful. Please check your emails to confirm your email address');
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<RegisterWithAddress>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  useEffect(() => {
    if (errors.addressLine1 || errors.addressLine2 || errors.city || errors.postcode || errors.ownerStatusId) {
      toast.error('There seems to be an error, please go back and check your address');
    }
  }, [errors]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl">Step 4: Register And Start Your Journey Today</h1>
        <p>Enter your details to create your account and begin your journey.</p>
      </div>

      <div className="flex flex-col gap-4">
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-2">
            <p className="font-bold">Email</p>
            <TextInput
              {...register('email')}
              id="email"
              name="email"
              title="Your email address"
              placeholder="Enter your email address"
              error={errors.email?.message}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">First Name</p>
            <TextInput
              {...register('firstName')}
              id="firstName"
              name="firstName"
              title="Your first name"
              placeholder="Enter your first name"
              error={errors.firstName?.message}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">Last Name</p>
            <TextInput
              {...register('lastName')}
              id="lastName"
              name="lastName"
              title="Your last name"
              placeholder="Enter your last name"
              error={errors.lastName?.message}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">Phone Number</p>
            <TextInput
              {...register('phone')}
              id="phone"
              name="phone"
              title="Your phone number"
              placeholder="Enter your phone number"
              error={errors.phone?.message}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">Password</p>
            <div className="flex flex-col gap-4">
              <SensitiveTextInput
                {...register('password')}
                id="password"
                name="password"
                title="Your password"
                placeholder="Password"
                error={errors.password?.message}
              />
              <SensitiveTextInput
                {...register('confirmPassword')}
                id="confirmPassword"
                name="confirmPassword"
                title="Confirm your password"
                placeholder="Confirm your password"
                error={errors.confirmPassword?.message}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <p>
              By signing up you agree to our{' '}
              <a href="privacy" target="blank" rel="noreferrer">
                <span className="text-dark font-bold underline">Privacy Policy.</span>
              </a>
            </p>
          </div>
        </form>

        <div className="flex flex-col gap-4 sm:flex-row">
          <button
            className="text-dark-dark bg-light enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold"
            onClick={() => setFormStage(FormStage.ADDRESS)}
          >
            Go Back
          </button>
          <button
            className="text-dark-dark bg-blue enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold"
            onClick={handleSubmit(onSubmit)}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserStage;
