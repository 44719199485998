import React, { useState } from 'react';
import { MdPlayArrow } from 'react-icons/md';
import ReactPlayer from 'react-player';
import { Button, Modal } from '@epcbuilder/lib/components';

const WatchExplainerVideo = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <Button
        className="font-header flex h-10 w-full min-w-fit items-center justify-center rounded-[36px] border px-2 font-sans text-base shadow hover:opacity-80 lg:h-16"
        overrideBaseClassnames={true}
        style="secondary"
        onClick={() => setShowModal(true)}
      >
        <div className="flex flex-row items-center gap-2">
          <div className="border-primary rounded-full border p-[2px]">
            <MdPlayArrow className="size-5 lg:size-8" />
          </div>
          <p className="text-xs uppercase lg:text-xl">See how it works</p>
        </div>
      </Button>
      {showModal && (
        <Modal maxWidth="sm:max-w-xl md:max-w-2xl" onClose={() => setShowModal(false)}>
          <ReactPlayer url="/video/explainer-video.mp4" playing={true} width="auto" controls={true} />
        </Modal>
      )}
    </>
  );
};

export default WatchExplainerVideo;
