import React from 'react';
import PageSection from '../PageSection';

const FinanaceFundingPage = () => {
  return (
    <div className="mx-4 my-7 flex flex-col gap-9 sm:mx-8 sm:my-6 md:mx-6 md:my-12 md:gap-12 xl:mx-auto xl:w-11/12 xl:max-w-screen-2xl">
      <div id="finance-intro" className="flex flex-col gap-4 md:gap-6">
        <h2 className="text-dark text-md text-2xl font-bold leading-tight md:text-[48px] xl:text-[64px]">
          Finance And Funding Explained
        </h2>
        <p className="text-base leading-7 md:text-xl md:leading-8 xl:text-2xl xl:leading-9">
          Your home upgrades could be part or fully funded, we will check and apply for any grant funding you might be
          eligible for, this includes all government initiatives as below. If you are not eligible, we have alternative
          finance options available.
        </p>
      </div>
      <PageSection title="Grant Options">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Energy Company Obligation (ECO)</h3>
            <p className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
              ECO is a government program designed to improve energy efficiency in homes and reduce carbon emissions.
              ECO offers free grants for energy-saving measures like insulation, heating upgrades, and renewable energy
              installations. It is primarily aimed at low-income households and those living in fuel poverty. You may be
              eligible if you receive certain means-tested benefits, such as income support, working tax credit, or
              housing benefit. You may also be eligible if you are classed as vulnerable, such as if you have debts,
              mental health conditions, or people aged 65+ or babies or children living at the property.
            </p>
          </div>
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Great British Insulation Scheme (GBIS)</h3>
            <p className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
              GBIS is a government initiative designed to help households improve the energy efficiency of their homes
              by providing insulation upgrades. Costs could be fully or partially covered, depending on the household's
              circumstances. As well as supporting low-income households, properties that have an EPC rating D to G, and
              which are in council tax bands A - D in England and A - E in Scotland and Wales may be eligible.
            </p>
          </div>
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Boiler Upgrade Scheme (BUS)</h3>
            <p className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
              BUS is aimed at helping homeowners transition from traditional gas and oil boilers to low-carbon heating
              systems. The scheme provides grants to help cover the cost of replacing fossil fuel heating systems with a
              heat pump or biomass boiler.
            </p>
            <ul className="list-inside list-disc">
              <li className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
                £7,500 towards an air source heat pump
              </li>
              <li className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
                £6,000 towards a biomass boiler
              </li>
            </ul>
          </div>
        </div>
      </PageSection>
      <PageSection title="Alternative Finance Options">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Personal Loan</h3>
            <p className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
              A loan to split the cost of the installation into equal monthly repayments over a choice of 12 - 60
              months.
            </p>
          </div>
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Homeowner Loan</h3>
            <p className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
              A Homeowner loan is a second charge mortgage (commonly referred to as a secured loan) that allows
              homeowners to get funds by using their home as security. This security generally gives access to higher
              amounts and longer terms than unsecured loans with borrowing from £10k to £250k over terms from 5 to 30
              years. A Homeowner loans sits behind your existing mortgage. It won't affect your current rate or
              repayments.
            </p>
          </div>
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Home Equity Line Of Credit (HELOC)</h3>
            <p className="text-base leading-7 md:leading-8 xl:text-lg xl:leading-9">
              A HELOC is a revolving line of credit (similar to a credit card) that's secured against your property.
              Rather than borrowing a lump sum as you would with a standard loan, you can continuously borrow against
              your line of credit up to a certain limit and within a set timeframe of 5 years. With a HELOC, you repay
              the amount borrowed, plus interest, in monthly instalments. You'll only ever pay interest on the funds you
              use. So, if your home improvements don't turn out to be as expensive as you first thought, you won't pay
              for the amount you haven't touched. At the end of 5 years your HELOC will turn into a standard loan which
              you repay until the end of the term (maximum 30 years).
            </p>
          </div>
        </div>
      </PageSection>
    </div>
  );
};

export default FinanaceFundingPage;
