import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import YourSavingsPage from '@/components/your-savings/YourSavingsPage';

const YourSavings = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="Your Savings" />
      </Helmet>
      <Layout title="EPC Builder Your Savings">
        <YourSavingsPage />
      </Layout>
    </>
  );
};

export default YourSavings;
