import { Register } from '@epcbuilder/lib/models/auth';
import { CreateAddress } from '@epcbuilder/lib/models/properties';

export type RegisterWithAddress = Register & CreateAddress;

export enum FormStage {
  POSTCODE = 0,
  ADDRESS = 1,
  EPC = 2,
  USER = 3,
}

export type ContactUsFormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
};
