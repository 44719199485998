import React from 'react';
import { Image } from '@epcbuilder/lib/components';

const QuickWinsPage = () => {
  return (
    <div className="min-h-screen bg-repeat px-6 lg:bg-[url('/images/lg-background.png')] lg:p-0">
      <div className="relative z-10 my-12 flex w-full flex-col md:my-24">
        <div className="relative z-10 text-center">
          <h2 className="text-primary mb-5 font-sans text-3xl font-black uppercase md:text-6xl md:leading-[90px]">
            12 tips to reduce your <br />
            energy bill
          </h2>
          <p className="text-large m-auto font-sans leading-[30px] md:text-2xl md:leading-[48px] lg:w-[1155px]">
            Saving energy at home doesn’t have to be complicated or costly. Here are some simple and practical tips to
            help you lower your energy bills:
          </p>
        </div>
      </div>
      <div className="space-y-14 lg:space-y-24">
        {tips.map((tip, index) => (
          <div key={index} className="flex flex-col items-center lg:flex-row">
            {index % 2 === 0 ? (
              <>
                <div className={`order-2 w-full lg:order-none lg:w-1/2`}>
                  <Image
                    src={tip.image}
                    className="w-full rounded-[30px] lg:rounded-e-[50px] lg:rounded-s-none"
                    alt={`Tip ${index + 1}`}
                  />
                </div>
                <div className="order-1 mb-10 flex w-full flex-col items-center lg:order-none lg:w-1/2">
                  <div className="m-auto space-y-5 lg:w-[393px] xl:w-[593]">
                    <h1 className="mb-5 text-center font-sans text-2xl font-black uppercase lg:mt-8 lg:text-start">
                      {tip.title}
                    </h1>
                    <p className="text-center font-sans leading-[28px] md:leading-[32px] lg:text-start lg:leading-[36px] xl:text-[18px]">
                      {tip.content.map((paragraph, idx) => (
                        <span key={idx}>
                          {paragraph}
                          {idx < tip.content.length - 1 && <br />}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mb-10 w-full lg:w-1/2">
                  <div className="m-auto space-y-5 lg:w-[393px] xl:w-[593]">
                    <h1 className="mb-5 text-center font-sans text-2xl font-black uppercase lg:mt-8 lg:text-start">
                      {tip.title}
                    </h1>
                    <p className="text-center leading-[28px] md:leading-[32px] lg:text-start lg:leading-[36px] xl:text-[18px]">
                      {tip.content.map((paragraph, idx) => (
                        <span key={idx}>
                          {paragraph}
                          {idx < tip.content.length - 1 && <br />}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <Image
                    src={tip.image}
                    className="w-full rounded-[30px] lg:rounded-e-none lg:rounded-s-[50px]"
                    alt={`Tip ${index + 1}`}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <div className="my-24 text-center">
        <p className="text-large m-auto font-sans leading-[30px] md:text-2xl md:leading-[48px] lg:w-[1155px]">
          Implementing these straightforward changes can lead to noticeable reductions in energy usage and lower utility
          bills, making your home more energy-efficient and eco-friendly.
        </p>
      </div>
    </div>
  );
};

const tips = [
  {
    title: 'Tip #1: Switch to LED Bulbs',
    content: [
      'Traditional incandescent bulbs are highly inefficient, converting only about 10% of the energy they use into light, with the rest wasted as heat.',
      'LED bulbs, on the other hand, use up to 80% less energy and can last up to 25 times longer. Not only do they reduce your energy consumption, but they also save you money in the long run with fewer replacements needed.',
      'Consider swapping out all the bulbs in your home for LEDs, especially in rooms where lights are frequently used.',
    ],
    image: '/images/quick-wins/tip-one.png',
  },
  {
    title: 'Tip #2: Turn Off Lights',
    content: [
      'It may sound simple, but turning off lights when you leave a room is one of the easiest ways to cut down on energy usage.',
      'This small habit can add up to significant savings over time, especially in homes where lights are often left on in unoccupied rooms. ',
      'To make it even easier, consider installing motion sensors or timers to automatically turn lights off when they’re not needed.',
    ],
    image: '/images/quick-wins/tip-two.png',
  },
  {
    title: 'Tip #3: Adjust Your Thermostat',
    content: [
      'Heating and cooling account for nearly half of a typical home’s energy use.',
      'By lowering your thermostat by just 1 degree in the winter, or raising it by 1 degree in the summer, you can save around 1% on your energy bill per degree.',
      'Programmable or smart thermostats make this even easier by allowing you to set schedules or control the temperature remotely, ensuring your home is comfortable only when you need it to be.',
    ],
    image: '/images/quick-wins/tip-three.png',
  },
  {
    title: 'Tip #4: Seal Drafts',
    content: [
      'Drafty windows and doors can lead to significant heat loss in the winter and heat gain in the summer, causing your heating and cooling systems to work harder than necessary. ',
      'Sealing these leaks with weather stripping, caulk, or door sweeps is a quick and inexpensive way to improve your home’s energy efficiency. ',
      'For an even greater impact, consider upgrading to energy-efficient windows and doors.',
    ],
    image: '/images/quick-wins/tip-four.png',
  },
  {
    title: 'Tip #5: Unplug Unused Electronics',
    content: [
      'Many electronic devices continue to draw power even when they’re turned off, a phenomenon known as "phantom" or "standby" power.',
      'Unplugging chargers, small appliances, and electronics when they’re not in use can prevent this wasted energy. ',
      'For convenience, use power strips to easily disconnect multiple devices at once, or invest in smart plugs that can be controlled remotely.',
    ],
    image: '/images/quick-wins/tip-five.png',
  },
  {
    title: 'Tip #6: Choose Energy-Efficient Appliances',
    content: [
      'When it’s time to replace old appliances, look for the ENERGY STAR label.',
      'ENERGY STAR-rated appliances are certified to use less energy than standard models, without sacrificing performance.',
      'From refrigerators and dishwashers to washing machines and air conditioners, upgrading to energy-efficient appliances can lead to substantial savings on your energy bill over time.',
    ],
    image: '/images/quick-wins/tip-six.png',
  },
  {
    title: 'Tip #7: Insulate Your Water Heater and Pipes',
    content: [
      'A lot of heat is lost from your water heater and the first few feet of hot water pipes, especially if they’re located in a cold area of your home like the basement or garage.',
      'By insulating your water heater and pipes, you can reduce this heat loss and ensure that your water heater doesn’t have to work as hard to keep water warm.',
      'Insulation blankets and pipe sleeves are inexpensive and easy to install, making this a quick win for energy savings.',
    ],
    image: '/images/quick-wins/tip-seven.png',
  },
  {
    title: 'Tip #8: Wash Laundry in Cold Water',
    content: [
      'Heating water accounts for about 90% of the energy used by washing machines. ',
      'By switching to cold water for most of your laundry loads, you can significantly reduce this energy use without compromising on cleanliness.',
      'Modern detergents are formulated to be effective in cold water, making this an easy change that won’t affect your laundry routine.',
    ],
    image: '/images/quick-wins/tip-eight.png',
  },
  {
    title: 'Tip #9: Air Dry Clothes',
    content: [
      'Clothes dryers are one of the most energy-intensive appliances in the home. Instead of using the dryer, consider air drying your clothes on a clothesline or drying rack.',
      'Not only does this save energy, but it also extends the life of your clothes by reducing wear and tear caused by high heat.',
      'On sunny days, outdoor drying can also give your clothes a fresh, natural scent.',
    ],
    image: '/images/quick-wins/tip-nine.png',
  },
  {
    title: 'Tip #10: Cover Pots While Cooking',
    content: [
      'When cooking on the stovetop, covering pots and pans with lids traps heat and allows food to cook faster, reducing the amount of energy needed.',
      'This is especially useful for boiling water or simmering dishes for extended periods.',
      'Additionally, using the right size burner for your pot can prevent energy waste—small pots on large burners waste a lot of heat.',
    ],
    image: '/images/quick-wins/tip-ten.png',
  },
  {
    title: 'Tip #11: Use a Microwave or Air Fryer',
    content: [
      'For smaller meals or quick snacks, using a microwave or air fryer can be much more energy-efficient than heating up the entire oven.',
      'Microwaves are particularly good for reheating leftovers or cooking simple meals, while air fryers use convection to cook food quickly with less energy. ',
      'These appliances are not only more energy-efficient but also save time, making them a convenient choice for everyday cooking.',
    ],
    image: '/images/quick-wins/tip-eleven.png',
  },
  {
    title: 'Tip #12: Take Shorter Showers',
    content: [
      'Hot water usage is a significant part of your home’s energy consumption.',
      'By cutting down your shower time by just a few minutes, you can save both water and the energy used to heat it.',
      'Installing a low-flow showerhead can also help reduce water usage without sacrificing water pressure, further enhancing your energy savings.',
    ],
    image: '/images/quick-wins/tip-twelve.png',
  },
];

export default QuickWinsPage;
