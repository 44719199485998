import React, { ReactElement, ReactNode } from 'react';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { MdOutlineApproval } from 'react-icons/md';
import { PiFileMagnifyingGlass } from 'react-icons/pi';
import { ColorfulHorizontalLine } from '@epcbuilder/lib/components/Lines';
import CarouselCard from './CarouselCard';
import QualityAssuranceCarousel from './QualityAssuranceCarousel';

interface IAccreditationBulletPoint {
  description: string;
  iconElement: ReactElement;
}

const QualityAssurancePage = () => {
  const accreditationBulletPoints: Array<IAccreditationBulletPoint> = [
    {
      description:
        'We have undergone rigorous training and certification processes which equips us with the knowledge and skills to complete work safely and to legally required standards.',
      iconElement: (
        <div className="h-auto w-16 min-w-16">
          <AiOutlineSafetyCertificate className="size-full" />
        </div>
      ),
    },
    {
      description: 'We are regularly assessed to provide assurance of ongoing competence',
      iconElement: (
        <div className="h-auto w-16 min-w-16">
          <PiFileMagnifyingGlass className="size-full" />
        </div>
      ),
    },
    {
      description: 'We hold the necessary insurances and have the right processes and procedures to assure compliance.',
      iconElement: (
        <div className="h-auto w-16 min-w-16">
          <MdOutlineApproval className="size-full" />
        </div>
      ),
    },
  ];

  const accreditedByElements: Array<ReactNode> = [
    {
      description:
        'Trustmark is the only UK government-endorsed quality scheme for home improvement work. It is designed to protect customers and ensure high standards of workmanship.',
      imgSrc: '/images/quality-assurance/trustmark.png',
    },
    {
      description:
        'MCS is a mark of quality, which demonstrates we have adhered to recognised industry standards when completing your renewable installation – highlighting quality, competency and compliance.',
      imgSrc: '/images/quality-assurance/mcs.png',
    },
    {
      description:
        'NICEIC registered tradespeople are regularly assessed to ensure their electrical installation work meets the relevant standards and legislation expected to keep you safe.',
      imgSrc: '/images/quality-assurance/niceic.png',
    },
    {
      description:
        "The IAA framework gives you the confidence that insulation work is carried out to the highest standards and subject to a level of rigor and surveillance which helps ensure it is done 'Right first time'.",
      imgSrc: '/images/quality-assurance/iaa.png',
    },
    {
      description:
        'HIES is a leading consumer protection organisation covering the installation of home energy products. Using a HIES member offers you additional protection in the event of an issue with the installation.',
      imgSrc: '/images/quality-assurance/hies.png',
    },
  ].map((accreditation, index) => (
    <CarouselCard
      key={`carousel-card-${index}`}
      description={accreditation.description}
      imgSrc={accreditation.imgSrc}
    />
  ));

  return (
    <div className="m-4 flex flex-col gap-8 md:m-16 md:gap-16 2xl:mx-auto 2xl:max-w-screen-2xl 2xl:px-16">
      <div id="accreditation-intro">
        <h2 className="text-dark text-md mb-3 font-bold uppercase md:text-4xl">Quality Assurance</h2>
        <p className="text-md md:text-xl">
          As a business we are committed to quality, safety and customer care. By selecting our professional accredited
          business, you can be confident that your home improvement installation is in safe and capable hands. As
          members of several respected accreditation schemes, we promise to ensure the quality and efficiency of your
          home upgrade.
        </p>
      </div>
      <div id="accreditation-bulletpoints">
        <h3 className="text-dark mb-2 font-sans text-base font-extrabold uppercase md:text-2xl">
          Being Accredited Means...
        </h3>
        <div className="flex flex-col gap-6">
          <ColorfulHorizontalLine width="10rem" />
          {accreditationBulletPoints.map((bulletPoint, index) => (
            <div key={`bulletpoint-${index}`} className="flex items-start gap-4">
              {bulletPoint.iconElement}
              <p className="text-md md:text-xl">{bulletPoint.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div id="our-accreditations">
        <h3 className="text-dark mb-2 font-sans text-base font-extrabold uppercase md:text-2xl">Our Accreditations</h3>
        <ColorfulHorizontalLine width="10rem" />
        <div className="hidden sm:block">
          <QualityAssuranceCarousel elements={accreditedByElements} />
        </div>
        <div className="mt-6 flex flex-col gap-6 sm:hidden">
          {accreditedByElements.map((element, index) => (
            <div
              className="bg-primary-darker relative h-full overflow-hidden rounded-2xl drop-shadow-xl"
              key={`accreditations-item-${index}`}
            >
              {element}
            </div>
          ))}
        </div>
      </div>
      <div id="guarantee-insurance">
        <h3 className="text-dark mb-2 font-sans text-base font-extrabold uppercase md:text-2xl">
          What happens if I'm not happy with the work?
        </h3>
        <ColorfulHorizontalLine width="10rem" />
        <p className="text-md mt-6 md:text-xl">
          We aim to ensure you are 100% happy with the work we carry out however we also recognise that sometimes things
          can go wrong.
          <br />
          <br />
          We <b>guarantee our workmanship for 2 years</b> from the date of installation.
          <br />
          <br />
          Products supplied by us come with the benefit of a <b>manufacturer's product guarantee</b>. Where a claim in
          respect of any of the products is notified to us by you in accordance with our terms and conditions, we will
          liaise with the manufacturer and use all reasonable endeavours to secure a replacement of the product (or the
          part in question), or a refund of the price of the product (or a proportionate part of the price).
          <br />
          <br />
          Lastly, we will provide you with <b>Insurance Backed Guarantees</b> for all measures installed. The insurance
          policy provides you with protection and cover should we cease to trade and there is an issue with your
          installation.
        </p>
      </div>
    </div>
  );
};

export default QualityAssurancePage;
