/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/RoofInsulation.glb --types 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Cube390: THREE.Mesh;
    Cube391: THREE.Mesh;
  };
  materials: {
    ['WOOD 1']: THREE.MeshStandardMaterial;
    ['grey.001']: THREE.MeshStandardMaterial;
    ['HIGHLIGHT.004']: THREE.MeshStandardMaterial;
  };
};

export function Model({ active, scale }: { active: boolean; scale: number }) {
  const { nodes, materials } = useGLTF('/models/RoofInsulation.glb') as GLTFResult;
  return (
    <group dispose={null} scale={scale}>
      <mesh
        geometry={nodes.Cube390.geometry}
        material={active ? materials['HIGHLIGHT.004'] : materials['grey.001']}
        position={[1.669, 4.11, -2.601]}
        rotation={[-Math.PI, 0, Math.PI / 2]}
      />
      <mesh
        geometry={nodes.Cube391.geometry}
        material={active ? materials['HIGHLIGHT.004'] : materials['WOOD 1']}
        position={[0.646, 4.217, 0]}
        rotation={[Math.PI / 4, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload('/models/RoofInsulation.glb');
