/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/house.glb --transform --types 
Files: ./public/models/house.glb [5.8MB] > house-transformed.glb [1.09MB] (81%)
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Cube002: THREE.InstancedMesh;
    Cube138: THREE.InstancedMesh;
    Circle071: THREE.InstancedMesh;
    Circle071_1: THREE.InstancedMesh;
    Cube359: THREE.InstancedMesh;
    Cube395: THREE.InstancedMesh;
    Cube390: THREE.InstancedMesh;
    Plane030: THREE.InstancedMesh;
    Plane040: THREE.InstancedMesh;
    Circle067: THREE.InstancedMesh;
    Circle067_1: THREE.InstancedMesh;
  };
  materials: {
    PaletteMaterial001: THREE.MeshStandardMaterial;
    PaletteMaterial002: THREE.MeshPhysicalMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/models/House.glb') as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube002.geometry} material={materials.PaletteMaterial001} position={[-0.859, 0.198, 0]} />
      <mesh
        geometry={nodes.Cube138.geometry}
        material={materials.PaletteMaterial002}
        position={[0.72, 3.202, -0.807]}
      />
      <group position={[0.283, 0.798, -2.715]} scale={3.266}>
        <mesh geometry={nodes.Circle071.geometry} material={materials.PaletteMaterial002} />
        <mesh geometry={nodes.Circle071_1.geometry} material={materials.PaletteMaterial001} />
      </group>
      <instancedMesh
        args={[nodes.Cube359.geometry, materials.PaletteMaterial001, 8]}
        instanceMatrix={nodes.Cube359.instanceMatrix}
      />
      <instancedMesh
        args={[nodes.Cube395.geometry, materials.PaletteMaterial001, 10]}
        instanceMatrix={nodes.Cube395.instanceMatrix}
      />
      <instancedMesh
        args={[nodes.Cube390.geometry, materials.PaletteMaterial001, 7]}
        instanceMatrix={nodes.Cube390.instanceMatrix}
      />
      <instancedMesh
        args={[nodes.Plane030.geometry, materials.PaletteMaterial001, 30]}
        instanceMatrix={nodes.Plane030.instanceMatrix}
      />
      <instancedMesh
        args={[nodes.Plane040.geometry, materials.PaletteMaterial001, 30]}
        instanceMatrix={nodes.Plane040.instanceMatrix}
      />
      <instancedMesh
        args={[nodes.Circle067.geometry, materials.PaletteMaterial001, 6]}
        instanceMatrix={nodes.Circle067.instanceMatrix}
      />
      <instancedMesh
        args={[nodes.Circle067_1.geometry, materials.PaletteMaterial001, 6]}
        instanceMatrix={nodes.Circle067_1.instanceMatrix}
      />
    </group>
  );
}

useGLTF.preload('/models/House.glb');
