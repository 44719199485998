import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Image } from '@epcbuilder/lib/components';
import { GetStarted } from '../get-started';
import WavyLines from '../wavy-lines';

type Difference = {
  label: string;
  description: string;
};

const differences: Difference[] = [
  {
    label: "We're with you every step of the way",
    description:
      'Project manage your retrofit from start to finish with our online tool which will guide you through the process in just five hassle free steps. We understand that these are big decisions, so our team is always on hand to assist you.',
  },
  {
    label: 'Our direct access to grants',
    description:
      "Researching suitable grants is daunting. We have over a decade of experience delivering home upgrade grants, in some cases the improvements could be completely free! If your property isn't eligible, we have alternative finance options available.",
  },
  {
    label: 'An independent point of view',
    description:
      "Using our experienced in-house team, we have been offering independent, unbiased advice since 2011. Because every property is different, we don't just provide an online report and leave you to it. We will visit your property and make bespoke recommendations.",
  },
];

const Card = ({ difference, index }: { difference: Difference; index: number }) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      const handleMediaChange = (event: MediaQueryListEvent) => {
        setIsMobile(event.matches);
      };

      const mediaQueryList = window.matchMedia('(max-width: 1023px)');
      mediaQueryList.addEventListener('change', handleMediaChange);

      setIsMobile(mediaQueryList.matches);
      return () => {
        mediaQueryList.removeEventListener('change', handleMediaChange);
      };
    }
  }, [mounted]);

  const mobileLabel = () => {
    switch (difference.label) {
      case "We're with you every step of the way":
        return (
          <span>
            We're
            <br />
            with you
            <br />
            every step
            <br />
            of the
            <br />
            way
          </span>
        );
      case 'Our direct access to grants':
        return (
          <span>
            Our
            <br />
            direct
            <br />
            access
            <br />
            to
            <br />
            grants
          </span>
        );
      case 'An independent point of view':
        return (
          <span>
            An
            <br />
            independent
            <br />
            point
            <br />
            of
            <br />
            view
          </span>
        );
    }
  };

  if (!mounted) return <></>;

  return (
    <div
      className={`${
        index % 2 === 0 ? 'pr-4' : 'pl-4'
      } relative flex w-full flex-row pb-8 lg:flex-col-reverse lg:gap-4 lg:p-0`}
    >
      {index < 2 && (
        <div className="via-neutral hidden h-0.5 w-full bg-gradient-to-r from-transparent to-transparent lg:block" />
      )}
      <div
        className={`${
          index % 2 === 0
            ? 'left-0 border-l-2 shadow-[4px_0px_10px_rgba(38,70,83,0.5)]'
            : 'right-0 border-r-2 shadow-[-4px_0px_10px_rgba(38,70,83,0.5)]'
        }  border-light absolute top-0 z-20 block h-44 lg:hidden`}
      />
      <button
        type="button"
        className={`${
          index % 2 === 0
            ? `${
                open ? '-left-1 gap-4 pl-8 pr-2' : '-left-56 px-8'
              } -left-1 flex-row-reverse rounded-r-[24px] drop-shadow-[10px_1px_5px_rgba(38,70,83,0.25)]`
            : `${
                open ? 'left-6 gap-4 pl-2 pr-8' : 'left-[246px] px-8'
              } -right-1 flex-row rounded-l-[24px] drop-shadow-[-10px_1px_5px_rgba(38,70,83,0.25)]`
        }  bg-light absolute top-2 z-10 flex h-40 w-[364px] cursor-pointer items-center justify-start py-4 transition-all duration-200 ease-in lg:relative lg:inset-0 lg:h-auto lg:w-full lg:cursor-auto lg:flex-row lg:bg-transparent lg:p-0 lg:drop-shadow-none lg:transition-none`}
        onClick={() => setOpen(!open)}
      >
        <div
          className={`${open ? 'size-6 rotate-[225deg]' : 'size-16 rotate-0'} ${
            index % 2 === 0 && open ? 'mr-4' : 'ml-4'
          } flex items-center justify-center transition-all duration-200 lg:hidden`}
        >
          <MdAdd size={open ? 24 : 42} className="text-primary" />
        </div>
        <p
          className={`${
            open ? 'opacity-100 transition-opacity delay-200 duration-75' : 'opacity-0'
          } max-w-[280px] text-sm lg:max-w-none lg:text-left lg:opacity-100`}
        >
          {difference.description}
        </p>
      </button>
      <div
        className={`${
          index % 2 === 0 ? 'ml-2 justify-end rounded-r-[16px] lg:ml-0' : 'mr-2 justify-start rounded-l-[16px] lg:mr-0'
        } bg-primary mt-4 flex h-36 w-full items-center p-4 lg:h-auto lg:justify-start lg:bg-transparent lg:p-0`}
      >
        <h1
          className={`${
            index % 2 === 0 ? 'text-right' : 'text-left'
          } text-light lg:text-dark flex h-full items-center text-xl uppercase leading-none lg:m-auto lg:text-center lg:font-black`}
        >
          {isMobile ? mobileLabel() : difference.label}
        </h1>
      </div>
    </div>
  );
};

const WhatMakesUsDifferent = () => {
  return (
    <div className="relative flex flex-col items-center">
      <div className="absolute left-0 top-12 w-full lg:top-44">
        <WavyLines />
      </div>
      <div className="relative flex w-full max-w-sm justify-center pb-8 pt-16 lg:max-w-none">
        <div className="bg-dark absolute left-[73px] top-0 -z-10 h-full w-1 lg:hidden" />
        <h1 className="font-header ml-16 max-w-[250px] text-4xl font-black uppercase lg:m-0 lg:max-w-none lg:text-5xl">
          What Makes Us Different?
        </h1>
      </div>
      <div className="flex w-full max-w-sm flex-col overflow-hidden lg:w-auto lg:max-w-none lg:flex-row-reverse lg:items-center lg:gap-4 xl:gap-16">
        <div className="flex w-full flex-col lg:max-w-[450px] lg:gap-4">
          {differences.map((difference, index) => (
            <div key={difference.label} className="relative">
              {index === 0 && <div className="bg-dark absolute left-[73px] h-2 w-1 lg:hidden" />}
              {index === 1 && <div className="bg-dark absolute -top-8 right-[73px] h-64 w-1 lg:hidden" />}
              {index === 2 && <div className="bg-dark absolute left-[73px] top-40 h-16 w-1 lg:hidden" />}
              <Card difference={difference} index={index} />
            </div>
          ))}
        </div>
        <div className="relative mt-8 flex h-80 w-full max-w-sm flex-row items-end justify-center overflow-hidden rounded-xl p-4 lg:mt-0 lg:h-[440px] lg:max-w-none">
          <Image
            src="/images/what-makes-us-different.png"
            className="absolute inset-0 size-full object-cover object-[-50px_0px]"
            alt="What makes us different"
          />
          <GetStarted inline={true} />
        </div>
      </div>
    </div>
  );
};

export default WhatMakesUsDifferent;
