import React from 'react';

const WavyLines = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 3757 1080"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
    >
      <g>
        <path
          d="M2283.58,525.372c724.99,290.55 1280.77,-123.972 1468.03,-367.552l5.39,-70.885c-259.61,350.734 -822.3,669.523 -1470.45,399.477c-439.47,-33.427 -1083.35,-722.893 -1688.91,-400.348c585.926,-278.616 1196.41,334.814 1685.94,439.308Z"
          fill="url(#_Linear1)"
          fillRule="nonzero"
        />
        <path
          d="M1435.92,530.726c-744.938,-234.726 -1267.67,220.755 -1435.92,477.834l0,71.09c232.26,-369.413 769.149,-729.961 1435.92,-509.851c440.74,0 1135.05,638.641 1714.4,271.101c-563.1,322.25 -1218.35,-243.109 -1714.4,-310.174Z"
          fill="url(#_Linear2)"
          fillRule="nonzero"
        />
      </g>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-3400.43,-27.917,27.917,-3400.43,3866.57,230.293)"
        >
          <stop offset="0" stopColor="#e76f51" stopOpacity={0.1} />
          <stop offset="0.27" stopColor="#f4a261" stopOpacity={0.08} />
          <stop offset="0.52" stopColor="#e9c469" stopOpacity={0.07} />
          <stop offset="0.76" stopColor="#2a9c8e" stopOpacity={0.06} />
          <stop offset="1" stopColor="#0d695c" stopOpacity={0.04} />
        </linearGradient>
        <linearGradient
          id="_Linear2"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(3392.74,-230.072,230.072,3392.74,-120.124,945.02)"
        >
          <stop offset="0" stopColor="#e76f51" stopOpacity={0.1} />
          <stop offset="0.27" stopColor="#f4a261" stopOpacity={0.08} />
          <stop offset="0.52" stopColor="#e9c469" stopOpacity={0.07} />
          <stop offset="0.76" stopColor="#2a9c8e" stopOpacity={0.06} />
          <stop offset="1" stopColor="#0d695c" stopOpacity={0.04} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WavyLines;
