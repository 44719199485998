import React from 'react';
import { CookieModal } from '@epcbuilder/lib/components';
import { Main } from '@epcbuilder/lib/components/Layout';
import { Children } from '@epcbuilder/lib/utils';
import { Footer, TopBar } from '@/components/navigation';

const Layout = ({ title, children }: { title: string; children: Children }) => {
  return (
    <Main title={title}>
      <CookieModal />
      <TopBar />
      <div className="relative h-full min-h-screen pt-24">{children}</div>
      <Footer />
    </Main>
  );
};

export default Layout;
