import React from 'react';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import { GetStarted } from '../get-started';

type Testimonial = {
  quote: string;
  author: string;
  stars: number;
};

const fakeTestimonials: Testimonial[] = [
  {
    quote:
      'Fantastic service. Great communication through the whole process of applying for a grant for an upgrade to my grandmas 45 year old boiler. Really fast turnaround, installers clean and efficient. Would highly recommend.',
    author: 'Jordan Cain',
    stars: 5,
  },
  {
    quote:
      'From start to finish Caroline Carrol and all the team from Improveasy worked really fast and professionally when arranging and installing my new boiler with thermostats on all the radiators. Everyone was so helpful, I would recommend them to all. Thank you so much! The fitters took all the waste and worked very fast during the installation',
    author: 'Christine Robinson',
    stars: 5,
  },
  {
    quote:
      'This firm were very efficient with the work they did for me. They came on time and the work was completed the same day. I would recommend Improveasy for any work you need doing. A special thank you to Harry and Caroline who were very supportive and answered any questions I had.',
    author: 'Karen Riley',
    stars: 5,
  },
];

const Testimonials = () => {
  return (
    <div className="relative flex flex-col items-center gap-8 overflow-hidden px-4 pb-8 pt-16 lg:pt-32">
      <div className="relative flex flex-col items-center gap-8 py-8 lg:flex-row lg:px-32 lg:py-4 xl:gap-16">
        <div className="absolute inset-20 -z-10 flex flex-col lg:flex-row">
          <div
            style={{ transform: 'translate3d(0,0,0)' }}
            className="from-secondary-dark via-secondary to-secondary-light w-full flex-1 bg-gradient-to-b blur-3xl lg:bg-gradient-to-r"
          />
          <div
            style={{ transform: 'translate3d(0,0,0)' }}
            className="from-secondary-light via-primary to-primary-dark w-full flex-1 bg-gradient-to-b blur-3xl lg:bg-gradient-to-r"
          />
        </div>
        {fakeTestimonials.map((testimonial, index) => (
          <div key={index} className="h-92 flex w-72 flex-col gap-4 rounded-xl bg-[#fff] p-4">
            <div className="text-secondary flex flex-row gap-1">
              {Array.from({ length: testimonial.stars }, (_, index) => index).map((index) => (
                <div key={`${index}-star`}>
                  <FaStar />
                </div>
              ))}
              {testimonial.stars % 1 !== 0 && (
                <div key="half-star">
                  <FaStarHalf />
                </div>
              )}
            </div>
            <p>“{testimonial.quote}”</p>
            <div className="flex flex-col gap-1">
              <p className="text-primary text-sm font-bold">{testimonial.author}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="via-neutral h-0.5 w-72 bg-gradient-to-r from-transparent lg:w-full lg:max-w-4xl" />
      <div className="max-w-sm">
        <GetStarted />
      </div>
      <div className="max-w-xl flex-col space-y-2 text-center">
        <p className="font-bold">
          Please note that our services are subject to regional availability, currently we focus on providing support to
          customers within the Northwest, and some surrounding areas.
        </p>
        <p className="font-bold">Please stay tuned for updates as we are quickly expanding.</p>
      </div>
      <div
        style={{ transform: 'translate3d(0,0,0)' }}
        className="bg-primary absolute bottom-[-300px] left-1/2 -z-10 size-80 w-96 -translate-x-1/2 rounded-full blur-3xl lg:bottom-[-350px] lg:h-96"
      />
    </div>
  );
};

export default Testimonials;
