import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';

const Refund = () => {
  const SupportEmail = () => {
    return (
      <a className="text-[#227D72] underline hover:opacity-80" href="mailto:support@epcbuilder.com">
        support@epcbuilder.com
      </a>
    );
  };

  const SupportPhone = () => {
    return (
      <a className="text-[#227D72] underline hover:opacity-80" href="tel:08000584140 ">
        08000584140
      </a>
    );
  };

  const PageSection = ({ title, children }: { title: string; children: ReactNode }) => {
    return (
      <div className="flex flex-col gap-1">
        <h2>{title}</h2>
        <div className="flex flex-col gap-2">{children}</div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>EPC Builder - Refund Policy</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Assessments Refund Policy">
        <div className="bg-primary flex h-48 w-full items-center justify-center">
          <h1 className="text-light text-center text-2xl">Assessments Refund Policy</h1>
        </div>
        <Container>
          <div className="my-8 flex flex-col gap-6">
            <PageSection title="Cancellation Policy:">
              <p>
                No charges will be made for cancellations that occur more than 48 hours before the scheduled survey. If
                you have any issues, please contact our support team by email at <SupportEmail /> or by phone at{' '}
                <SupportPhone />.
              </p>
              <p>
                To cancel your booking please notify us as soon as possible. You can cancel your booking by following
                the steps on the customer portal, or by contacting us at <SupportEmail /> or by phone at{' '}
                <SupportPhone />.
              </p>
              <p>If the refund request is approved, you will receive the refund within 7 business days.</p>
            </PageSection>
            <PageSection title="Refunds and Charges:">
              <ul className="ml-8">
                <li className="list-disc text-base">
                  Cancellations made within 24 hours of the scheduled booking time will result in a charge for the full
                  amount of the booking.
                </li>
                <li className="list-disc text-base">
                  Cancellations made between 24 - 48 hours of the scheduled booking time will result in a charge of 50%
                  of the booking amount.
                </li>
              </ul>
            </PageSection>
            <PageSection title="Exceptions:">
              <ul className="ml-8">
                <li className="list-disc text-base">
                  In the event of unforeseen circumstances or emergencies, please get in touch with our team and we will
                  try to help as best we can.
                </li>
              </ul>
            </PageSection>
            <PageSection title="No-Show Policy:">
              <ul className="ml-8">
                <li className="list-disc text-base">
                  If you fail to accommodate our energy assessor on the scheduled booking date and time without prior
                  notice, you will be considered a no-show, and the full booking amount will be charged.
                </li>
                <li className="list-disc text-base">
                  Please contact us at <SupportEmail /> or by phone at <SupportPhone /> immediately if such a situation
                  arises.
                </li>
              </ul>
            </PageSection>
            <PageSection title="Modifications:">
              <ul className="ml-8">
                <li className="list-disc text-base">
                  You can modify your booking via the link in your confirmation email or directly through the customer
                  portal.
                </li>
                <li className="list-disc text-base">
                  If you need to modify your booking please do this before 48 hours of the scheduled survey. You will
                  not be able to make changes after this period.
                </li>
              </ul>
              <p>
                By booking with us, you acknowledge and agree to abide by this cancellation policy. We recommend
                reviewing this policy carefully before confirming your reservation. If you have any questions or
                concerns, please do not hesitate to contact us at <SupportEmail /> or by phone at <SupportPhone /> for
                clarification.
              </p>
              <p>Thank you for choosing our services. We're here to help you in any way we can. </p>
            </PageSection>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Refund;
