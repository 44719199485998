/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/Boiler.glb --types 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Cube208: THREE.Mesh;
    Circle027: THREE.Mesh;
    Circle028: THREE.Mesh;
    Circle029: THREE.Mesh;
    Circle030: THREE.Mesh;
    Circle031: THREE.Mesh;
    Cube209: THREE.Mesh;
    Cube383: THREE.Mesh;
    Cube384: THREE.Mesh;
    Cube388: THREE.Mesh;
    Cube385: THREE.Mesh;
    Cube179: THREE.Mesh;
  };
  materials: {
    measures: THREE.MeshStandardMaterial;
    ['grey metalic']: THREE.MeshStandardMaterial;
    ['GOLD metalic.001']: THREE.MeshStandardMaterial;
    ['white metalic']: THREE.MeshStandardMaterial;
    ['white mirror']: THREE.MeshStandardMaterial;
    ['HIGHLIGHT.002']: THREE.MeshStandardMaterial;
  };
};

export function Model({ active, scale }: { active: boolean; scale: number }) {
  const { nodes, materials } = useGLTF('/models/Boiler.glb') as GLTFResult;
  return (
    <group dispose={null} scale={scale}>
      <mesh
        geometry={nodes.Cube208.geometry}
        material={active ? materials['HIGHLIGHT.002'] : materials.measures}
        position={[-0.265, 1.308, -2.585]}
      >
        <mesh
          geometry={nodes.Circle027.geometry}
          material={active ? materials['HIGHLIGHT.002'] : materials['grey metalic']}
          position={[0, 0.467, -0.144]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={1.244}
        />
        <mesh
          geometry={nodes.Circle028.geometry}
          material={active ? materials['HIGHLIGHT.002'] : materials['GOLD metalic.001']}
          position={[-0.141, -0.445, -0.075]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={1.244}
        />
        <mesh
          geometry={nodes.Circle029.geometry}
          material={active ? materials['HIGHLIGHT.002'] : materials['GOLD metalic.001']}
          position={[-0.048, -0.445, -0.075]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={1.244}
        />
        <mesh
          geometry={nodes.Circle030.geometry}
          material={active ? materials['HIGHLIGHT.002'] : materials['GOLD metalic.001']}
          position={[0.046, -0.445, -0.075]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={1.244}
        />
        <mesh
          geometry={nodes.Circle031.geometry}
          material={active ? materials['HIGHLIGHT.002'] : materials['GOLD metalic.001']}
          position={[0.14, -0.445, -0.075]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={1.244}
        />
        <mesh
          geometry={nodes.Cube209.geometry}
          material={active ? materials['HIGHLIGHT.002'] : materials.measures}
          position={[0, -0.339, 0]}
        >
          <mesh
            geometry={nodes.Cube383.geometry}
            material={active ? materials['HIGHLIGHT.002'] : materials['white metalic']}
            position={[0, -0.045, -0.049]}
          />
        </mesh>
        <mesh
          geometry={nodes.Cube384.geometry}
          material={active ? materials['HIGHLIGHT.002'] : materials['white mirror']}
          position={[0, -0.071, 0.037]}
        />
      </mesh>
      <mesh
        geometry={nodes.Cube388.geometry}
        material={active ? materials['HIGHLIGHT.002'] : materials.measures}
        position={[-0.265, 1.308, -2.585]}
      >
        <mesh geometry={nodes.Cube385.geometry} material={active ? materials['HIGHLIGHT.002'] : materials.measures} />
      </mesh>
    </group>
  );
}

useGLTF.preload('/models/Boiler.glb');
