/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/UnderfloorInsulation.glb --types 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Cube012: THREE.Mesh;
    Cube014: THREE.Mesh;
    Cube015: THREE.Mesh;
    Cube018: THREE.Mesh;
    Cube019: THREE.Mesh;
    Cube021: THREE.Mesh;
    Cube022: THREE.Mesh;
    Cube023: THREE.Mesh;
    Cube024: THREE.Mesh;
    Cube025: THREE.Mesh;
    Cube026: THREE.Mesh;
    Cube027: THREE.Mesh;
    Cube028: THREE.Mesh;
    Cube029: THREE.Mesh;
    Cube030: THREE.Mesh;
    Cube031: THREE.Mesh;
    Cube032: THREE.Mesh;
    Cube033: THREE.Mesh;
    Cube034: THREE.Mesh;
    Cube035: THREE.Mesh;
    Cube036: THREE.Mesh;
    Cube037: THREE.Mesh;
    Cube038: THREE.Mesh;
    Cube039: THREE.Mesh;
    Cube040: THREE.Mesh;
    Cube041: THREE.Mesh;
    Cube042: THREE.Mesh;
    Cube043: THREE.Mesh;
    Cube044: THREE.Mesh;
    Cube045: THREE.Mesh;
    Cube046: THREE.Mesh;
    Cube047: THREE.Mesh;
    Cube048: THREE.Mesh;
    Cube049: THREE.Mesh;
    Cube050: THREE.Mesh;
    Cube051: THREE.Mesh;
    Cube052: THREE.Mesh;
    Cube053: THREE.Mesh;
    Cube054: THREE.Mesh;
    Cube055: THREE.Mesh;
    Cube056: THREE.Mesh;
    Cube057: THREE.Mesh;
    Cube058: THREE.Mesh;
    Cube059: THREE.Mesh;
    Cube060: THREE.Mesh;
    Cube061: THREE.Mesh;
    Cube062: THREE.Mesh;
    Cube063: THREE.Mesh;
    Cube064: THREE.Mesh;
    Cube065: THREE.Mesh;
    Cube066: THREE.Mesh;
    Cube067: THREE.Mesh;
    Cube068: THREE.Mesh;
    Cube069: THREE.Mesh;
    Cube070: THREE.Mesh;
    Cube071: THREE.Mesh;
    Cube072: THREE.Mesh;
    Cube073: THREE.Mesh;
    Cube074: THREE.Mesh;
    Cube075: THREE.Mesh;
    Cube076: THREE.Mesh;
    Cube077: THREE.Mesh;
    Cube078: THREE.Mesh;
    Cube079: THREE.Mesh;
    Cube080: THREE.Mesh;
    Cube081: THREE.Mesh;
    Cube082: THREE.Mesh;
    Cube083: THREE.Mesh;
    Cube084: THREE.Mesh;
    Cube085: THREE.Mesh;
    Cube086: THREE.Mesh;
    Cube087: THREE.Mesh;
    Cube088: THREE.Mesh;
    Cube089: THREE.Mesh;
    Cube090: THREE.Mesh;
    Cube091: THREE.Mesh;
    Cube092: THREE.Mesh;
    Cube093: THREE.Mesh;
    Cube094: THREE.Mesh;
    Cube095: THREE.Mesh;
    Cube096: THREE.Mesh;
    Cube097: THREE.Mesh;
    Cube098: THREE.Mesh;
    Cube099: THREE.Mesh;
    Cube100: THREE.Mesh;
    Cube101: THREE.Mesh;
    Cube102: THREE.Mesh;
    Cube103: THREE.Mesh;
    Cube104: THREE.Mesh;
    Cube105: THREE.Mesh;
    Cube106: THREE.Mesh;
    Cube107: THREE.Mesh;
    Cube108: THREE.Mesh;
    Cube109: THREE.Mesh;
    Cube110: THREE.Mesh;
    Cube111: THREE.Mesh;
    Cube112: THREE.Mesh;
    Cube113: THREE.Mesh;
    Cube114: THREE.Mesh;
    Cube115: THREE.Mesh;
    Cube116: THREE.Mesh;
    Cube117: THREE.Mesh;
    Cube118: THREE.Mesh;
    Cube119: THREE.Mesh;
    Cube120: THREE.Mesh;
    Cube121: THREE.Mesh;
    Cube122: THREE.Mesh;
    Cube123: THREE.Mesh;
    Cube124: THREE.Mesh;
    Cube125: THREE.Mesh;
    Cube126: THREE.Mesh;
    Cube127: THREE.Mesh;
    Cube301: THREE.Mesh;
    Cube017: THREE.Mesh;
  };
  materials: {
    ['WOOD 2']: THREE.MeshStandardMaterial;
    ['WOOD 1']: THREE.MeshStandardMaterial;
    HIGHLIGHT: THREE.MeshStandardMaterial;
  };
};

export function Model({ active, scale }: { active: boolean; scale: number }) {
  const { nodes, materials } = useGLTF('/models/UnderfloorInsulation.glb') as GLTFResult;
  return (
    <group dispose={null} scale={scale}>
      <group position={[-0.859, 0.258, 0]}>
        <mesh geometry={nodes.Cube012.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube014.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube015.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube018.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube019.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube021.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube022.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube023.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube024.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube025.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube026.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube027.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube028.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube029.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube030.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube031.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube032.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube033.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube034.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube035.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube036.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube037.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube038.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube039.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube040.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube041.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube042.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube043.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube044.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube045.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube046.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube047.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube048.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube049.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube050.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube051.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube052.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube053.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube054.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube055.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube056.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube057.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube058.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube059.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube060.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube061.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube062.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube063.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube064.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube065.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube066.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube067.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube068.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube069.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube070.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube071.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube072.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube073.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube074.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube075.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube076.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube077.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube078.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube079.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube080.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube081.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube082.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube083.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube084.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube085.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube086.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube087.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube088.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube089.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube090.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube091.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube092.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube093.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube094.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube095.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube096.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube097.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube098.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube099.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube100.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube101.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube102.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube103.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube104.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube105.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube106.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube107.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube108.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube109.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube110.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube111.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube112.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube113.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube114.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube115.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube116.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube117.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube118.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 2']} />
        <mesh geometry={nodes.Cube119.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube120.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube121.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube122.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube123.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube124.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube125.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube126.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh geometry={nodes.Cube127.geometry} material={active ? materials.HIGHLIGHT : materials['WOOD 1']} />
        <mesh
          geometry={nodes.Cube301.geometry}
          material={active ? materials.HIGHLIGHT : materials['WOOD 1']}
          position={[-0.387, 0, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/models/UnderfloorInsulation.glb');
