import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorBoundary, initializeAppInsights } from '@epcbuilder/lib/components';
import { ToastProvider } from '@epcbuilder/lib/context/toastContext';
import useClarity from '@epcbuilder/lib/hooks/useClarity';
import { initApi } from '@epcbuilder/lib/utils';
import Routes from './routes';
import '@epcbuilder/lib/styles/globals.css';

initApi(import.meta.env.VITE_API_URL);
if (import.meta.env.NODE_ENV !== 'development') {
  initializeAppInsights({ instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_INSTRUMENTATION_KEY });
}

const App = () => {
  useClarity();

  return (
    <ToastProvider theme="light">
      <Helmet>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Helmet>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </ToastProvider>
  );
};

export default App;
