import React from 'react';
import { Helmet } from 'react-helmet';
import HomeImprovements from '@/components/landing-page/HomeImprovements';
import Layout from '@/components/Layout';

const HomeImprovementsPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Home Improvements">
        <HomeImprovements />
      </Layout>
    </>
  );
};

export default HomeImprovementsPage;
