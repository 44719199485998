import React, { ReactNode } from 'react';
import { ColorfulHorizontalLine } from '@epcbuilder/lib/components/Lines';
import { twMerge } from 'tailwind-merge';

interface IPageSectionProps {
  title: string;
  children: ReactNode;
  containerClassName?: string;
  childContainerClassName?: string;
  headerContainerClassName?: string;
}

const PageSection = ({
  title,
  children,
  containerClassName,
  childContainerClassName,
  headerContainerClassName,
}: IPageSectionProps) => {
  const baseContainerClassName = 'flex flex-col gap-4 md:gap-6';
  const finalContainerClassName = twMerge(baseContainerClassName, containerClassName);

  const baseHeaderContainerClassName = 'flex flex-col gap-2 md:gap-4';
  const finalHeaderContainerClassName = twMerge(baseHeaderContainerClassName, headerContainerClassName);

  const baseChildContainerClassName = '';
  const finalChildContainerClassName = twMerge(baseChildContainerClassName, childContainerClassName);

  return (
    <div className={finalContainerClassName}>
      <div className={finalHeaderContainerClassName}>
        <h2 className="font-[unset] text-lg font-black uppercase md:text-[22px] xl:text-2xl">{title}</h2>
        <ColorfulHorizontalLine width="10rem" />
      </div>
      <div className={finalChildContainerClassName}>{children}</div>
    </div>
  );
};

export default PageSection;
