import React from 'react';
import { Image } from '@epcbuilder/lib/components';
import usePreloadImage from '@epcbuilder/lib/hooks/usePreloadImage';
import { GetStarted } from '../get-started';
import { IncreasePropertyValue, LowerEnergyBills, ReduceCarbonEmissions } from '../Icons';
import { WatchExplainerVideo } from '../watch-explainer-video';

const Hero = () => {
  usePreloadImage('/images/hero.png');
  usePreloadImage('/images/get-started.png');

  return (
    <div className="from-light to-light/50 relative -mt-24 w-full bg-gradient-to-t pt-16 md:mt-0">
      <div className="bg-light absolute -top-24 left-0 -z-10 h-[99%] w-full overflow-hidden md:top-0">
        <Image src="/images/hero.png" alt="EPC Builder Hero" className="size-full object-cover" />
      </div>
      <div className="mx-auto flex h-fit max-w-sm flex-col items-center gap-8 px-4 py-8 lg:max-w-none lg:gap-16">
        <div className="flex flex-col gap-8 lg:flex-row lg:items-center lg:gap-24 xl:gap-4">
          <div className="flex flex-col gap-4 lg:gap-8">
            <h1 className="font-header text-dark max-w-sm text-4xl font-black lg:text-5xl">
              HOME ENERGY EFFICIENCY, MADE EASY
            </h1>
            <h2 className="font-header text-dark max-w-sm text-sm uppercase italic lg:text-xl xl:max-w-2xl">
              Why waste time searching for various installers, assessors, and finance solutions? We have it all under
              one roof.
            </h2>
            <div className="w-44 lg:w-72">
              <WatchExplainerVideo />
            </div>
          </div>
          <div className="relative z-10 lg:w-[500px] lg:max-w-none">
            <div className="absolute -inset-2 -z-10 flex flex-col">
              <div
                style={{ transform: 'translate3d(0,0,0)' }}
                className="from-primary-dark via-primary to-secondary-light w-full flex-1 bg-gradient-to-b blur-md"
              />
              <div
                style={{ transform: 'translate3d(0,0,0)' }}
                className="from-secondary-light via-secondary to-secondary-dark w-full flex-1 bg-gradient-to-b blur-md"
              />
            </div>
            <div className="relative z-10 flex flex-col gap-2 rounded-[22px] bg-white p-2 lg:gap-4 lg:p-3">
              <div className="relative h-32 w-full overflow-hidden rounded-[18px] lg:h-80">
                <Image src="/images/get-started.png" alt="Get your free epc rating now!" className="object-cover" />
              </div>
              <GetStarted />
            </div>
          </div>
        </div>
        <div className="text-light flex w-full flex-col items-center gap-4 lg:flex-row lg:justify-center xl:gap-28">
          <div className="bg-primary/70 flex flex-row items-center gap-4 rounded-[20px] p-4 lg:h-36 lg:max-w-xs">
            <div className=" flex flex-1 flex-col gap-2">
              <p className="text-base font-bold lg:text-lg">LOWER ENERGY BILLS</p>
              <p className="text-xs lg:text-base">
                Upgrading your property's energy efficiency reduces your running costs and creates a comfortable living
                space.
              </p>
            </div>
            <div className="size-14 lg:hidden">
              <LowerEnergyBills />
            </div>
          </div>
          <div className="bg-primary/70 flex flex-row-reverse items-center gap-4 rounded-[20px] p-4 lg:h-36 lg:max-w-xs lg:flex-row">
            <div className=" flex flex-1 flex-col gap-2">
              <p className="text-base font-bold lg:text-lg">INCREASED PROPERTY VALUE</p>
              <p className="text-xs lg:text-base">
                Energy efficient properties sell quicker and for a higher value than those with a poor EPC rating.
              </p>
            </div>
            <div className="size-14 lg:hidden">
              <IncreasePropertyValue />
            </div>
          </div>
          <div className="bg-primary/70 flex flex-row items-center gap-4 rounded-[20px] p-4 lg:h-36 lg:max-w-xs">
            <div className=" flex flex-1 flex-col gap-2">
              <p className="text-base font-bold lg:text-lg">REDUCED CARBON EMISSIONS</p>
              <p className="text-xs lg:text-base">
                Play your part to reduce Co2 with the most up to date energy efficient and renewable technology.
              </p>
            </div>
            <div className="size-14 lg:hidden">
              <ReduceCarbonEmissions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
