/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/SolarPanels.glb --transform --types 
Files: ./public/models/SolarPanels.glb [1.42MB] > SolarPanels-transformed.glb [239.2KB] (83%)
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Plane007: THREE.Mesh;
    Plane004: THREE.Mesh;
    Plane004_1: THREE.Mesh;
    Plane004_2: THREE.Mesh;
    Cube153: THREE.Mesh;
  };
  materials: {
    ['WOOD 2']: THREE.MeshStandardMaterial;
    white: THREE.MeshStandardMaterial;
    ['Material.002']: THREE.MeshStandardMaterial;
    grey: THREE.MeshStandardMaterial;
    ['HIGHLIGHT.008']: THREE.MeshStandardMaterial;
  };
};

export function Model({ active, scale }: { active: boolean; scale: number }) {
  const { nodes, materials } = useGLTF('/models/SolarPanels.glb') as GLTFResult;
  return (
    <group dispose={null} scale={scale}>
      <mesh
        geometry={nodes.Plane007.geometry}
        material={active ? materials['HIGHLIGHT.008'] : materials['WOOD 2']}
        position={[2.058, 5.361, 1.8]}
        rotation={[0.506, 0, 0]}
      >
        <group position={[-0.836, 0.017, -0.53]}>
          <mesh geometry={nodes.Plane004.geometry} material={active ? materials['HIGHLIGHT.008'] : materials.white} />
          <mesh
            geometry={nodes.Plane004_1.geometry}
            material={active ? materials['HIGHLIGHT.008'] : materials['Material.002']}
          />
          <mesh geometry={nodes.Plane004_2.geometry} material={active ? materials['HIGHLIGHT.008'] : materials.grey} />
        </group>
      </mesh>
    </group>
  );
}

useGLTF.preload('/models/SolarPanels.glb');
