import React, { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Loading, NewSelect, TextInput } from '@epcbuilder/lib/components';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { CreateAddress } from '@epcbuilder/lib/models/properties';
import { POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormStage } from '@/models/generic';

const addressConfirmationSchema = yup.object().shape({
  addressLine1: yup.string().required('Address Line 1 must not be empty'),
  addressLine2: yup.string().optional(),
  city: yup.string().required('City must not be empty'),
  postcode: yup
    .string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
  ownerStatusId: yup.number().required('Owner Status must be entered').min(1, 'Owner Status must not be empty'),
});

const AddressStage = ({
  address,
  handleAddressUpdate,
  setFormStage,
}: {
  address: CreateAddress;
  handleAddressUpdate: (address: CreateAddress) => void;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const { statusOptions, isLoading: statusOptionsLoading } = usePropertyOwnerStatuses();
  const defaultValues: CreateAddress = {
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    postcode: address.postcode,
    ownerStatusId: address.ownerStatusId,
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateAddress>({
    defaultValues,
    resolver: yupResolver(addressConfirmationSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<CreateAddress> = async (data) => {
    handleAddressUpdate({
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      postcode: data.postcode.trim(),
      ownerStatusId: data.ownerStatusId,
    });
  };

  if (isSubmitting || statusOptionsLoading || !statusOptions) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl">Step 2: Confirm Your Property Details</h1>
        <p>Confirm your ownership and address details so we know we've got the right property.</p>
      </div>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2">
          <p className="font-bold">Owner Status</p>
          <NewSelect
            className="rounded-[10px]"
            control={control}
            id="ownerStatusId"
            name="ownerStatusId"
            title="Select owner status"
            placeholder="Select owner status"
            error={errors.ownerStatusId?.message}
            options={statusOptions}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold">Address</p>
          <TextInput
            {...register('addressLine1')}
            id="addressLine1"
            name="addressLine1"
            title="The first line for your address"
            placeholder="Address Line 1"
            error={errors.addressLine1?.message}
          />
          <TextInput
            {...register('addressLine2')}
            id="addressLine2"
            name="addressLine2"
            title="The second line for your address"
            placeholder="Address Line 2"
            error={errors.addressLine2?.message}
          />
          <TextInput
            {...register('city')}
            id="city"
            name="city"
            title="Your city"
            placeholder="City"
            error={errors.city?.message}
          />
          <TextInput
            {...register('postcode')}
            id="postcode"
            name="postcode"
            title="Your postcode"
            placeholder="Postcode"
            error={errors.postcode?.message}
          />
        </div>
      </form>

      <div className="flex flex-col gap-4 sm:flex-row">
        <button
          className="text-dark-dark bg-light  enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold"
          onClick={() => setFormStage(FormStage.POSTCODE)}
        >
          Search Manually
        </button>
        <button
          className="text-dark-dark bg-blue  enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold"
          onClick={handleSubmit(onSubmit)}
        >
          See Your EPC Rating
        </button>
      </div>
    </div>
  );
};

export default AddressStage;
