import React from 'react';
import { Helmet } from 'react-helmet';
import { Hero, HowDoesEpcBuilderWork, Testimonials, WhatMakesUsDifferent } from '@/components/landing-page';
import Layout from '@/components/Layout';

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Landing Page">
        <Hero />
        <HowDoesEpcBuilderWork />
        <WhatMakesUsDifferent />
        <Testimonials />
      </Layout>
    </>
  );
};

export default LandingPage;
