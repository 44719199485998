import React from 'react';

export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="102" height="110" viewBox="0 0 102 110" fill="none">
    <path
      d="M101.347 45.1097L51.5856 0.224253C51.2539 -0.0747509 50.7475 -0.0747509 50.4158 0.224253L0.654613 45.1097C0.386165 45.352 0.294499 45.7339 0.42545 46.0722C0.554218 46.4105 0.879411 46.6331 1.23952 46.6331H11.9731C12.1892 46.6331 12.3965 46.5523 12.558 46.4083L12.8068 46.1835V109.127C12.8068 109.609 13.1975 110 13.6798 110H40.337C40.8193 110 41.21 109.609 41.21 109.127V103.839H88.3215C88.8039 103.839 89.1945 103.448 89.1945 102.966V46.1813L89.4434 46.4061C89.6027 46.5502 89.8122 46.6309 90.0283 46.6309H100.762C101.124 46.6309 101.447 46.4083 101.576 46.07C101.705 45.7339 101.613 45.3498 101.347 45.1075V45.1097ZM53.683 21.1218H48.3184V19.3474H53.683V21.1218ZM53.683 22.8678V24.6421H48.3184V22.8678H53.683ZM48.3184 26.3881H53.683V28.1625H48.3184V26.3881ZM25.2165 73.921C25.2165 73.4321 25.6225 73.0327 26.1201 73.0327C26.6177 73.0327 27.0237 73.4321 27.0237 73.921C27.0237 74.4099 26.6177 74.8071 26.1201 74.8071C25.6225 74.8071 25.2165 74.4099 25.2165 73.921ZM39.464 102.091H14.5528V97.6754H39.464V102.091ZM39.464 91.5163V95.9294H14.5528V91.5163H39.464ZM41.21 102.091V57.1986C41.21 56.7163 40.8193 56.3256 40.337 56.3256H20.7882C20.3059 56.3256 19.9152 56.7163 19.9152 57.1986V89.7703H14.5528V44.6055L15.2359 43.9901H86.7676L87.4507 44.6055V102.091H41.2121H41.21ZM90.3644 44.8849L88.9392 43.6016L88.9196 43.5819C88.9196 43.5819 88.913 43.5732 88.9086 43.5688L51.5856 9.90586C51.2539 9.60685 50.7475 9.60685 50.4158 9.90586L13.0949 43.5688C13.0949 43.5688 13.0884 43.5776 13.084 43.5819L11.637 44.8849H3.51151L51.0007 2.04883L98.4899 44.8849H90.3622H90.3644Z"
      fill="#2A9C8E"
    />
    <path
      d="M82.1031 56.3252H46.5588C46.0765 56.3252 45.6858 56.7159 45.6858 57.1982V74.8001C45.6858 75.2825 46.0765 75.6731 46.5588 75.6731H82.1031C82.5854 75.6731 82.9761 75.2825 82.9761 74.8001V57.1982C82.9761 56.7159 82.5854 56.3252 82.1031 56.3252ZM63.4579 58.0712V65.1272H47.4318V58.0712H63.4579ZM65.204 73.9271V66.8733H81.2301V73.9271H65.204Z"
      fill="#F4A261"
    />
  </svg>
);
