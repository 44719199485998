/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/models/AirSourceHeatPump.glb --types 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Cube357: THREE.Mesh;
    Circle022: THREE.Mesh;
    Cube371: THREE.Mesh;
    Cube372: THREE.Mesh;
    Cube373: THREE.Mesh;
    Cube374: THREE.Mesh;
    Plane019: THREE.Mesh;
    Plane020: THREE.Mesh;
    Plane021: THREE.Mesh;
    Plane022: THREE.Mesh;
    Plane023: THREE.Mesh;
    Plane024: THREE.Mesh;
    Cube180: THREE.Mesh;
  };
  materials: {
    measures: THREE.MeshStandardMaterial;
    roof: THREE.MeshStandardMaterial;
    white: THREE.MeshStandardMaterial;
    ['HIGHLIGHT.003']: THREE.MeshStandardMaterial;
  };
};

export function Model({ active, scale }: { active: boolean; scale: number }) {
  const { nodes, materials } = useGLTF('/models/AirSourceHeatPump.glb') as GLTFResult;
  return (
    <group dispose={null} scale={scale}>
      <mesh
        geometry={nodes.Cube357.geometry}
        material={active ? materials['HIGHLIGHT.003'] : materials.measures}
        position={[3.142, 0.617, 0.067]}
      >
        <mesh
          geometry={nodes.Circle022.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.roof}
          position={[0.266, 0, 0]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh geometry={nodes.Cube371.geometry} material={active ? materials['HIGHLIGHT.003'] : materials.roof} />
        <mesh
          geometry={nodes.Cube372.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.roof}
          position={[0.405, 0, -0.514]}
        />
        <mesh geometry={nodes.Cube373.geometry} material={active ? materials['HIGHLIGHT.003'] : materials.roof} />
        <mesh
          geometry={nodes.Cube374.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.roof}
          position={[0.204, -0.196, 0.403]}
        />
        <mesh
          geometry={nodes.Plane019.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.white}
          position={[0.309, 0, 0]}
          rotation={[0, 0, -1.366]}
        />
        <mesh
          geometry={nodes.Plane020.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.white}
          position={[0.309, 0, 0]}
          rotation={[1.222, 0, -1.366]}
        />
        <mesh
          geometry={nodes.Plane021.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.white}
          position={[0.309, 0, 0]}
          rotation={[2.443, 0, -1.366]}
        />
        <mesh
          geometry={nodes.Plane022.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.white}
          position={[0.309, 0, 0]}
          rotation={[-2.618, 0, -1.366]}
        />
        <mesh
          geometry={nodes.Plane023.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.white}
          position={[0.309, 0, 0]}
          rotation={[-1.396, 0, -1.366]}
        />
        <mesh
          geometry={nodes.Plane024.geometry}
          material={active ? materials['HIGHLIGHT.003'] : materials.roof}
          position={[0.381, 0, 0.214]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload('/models/AirSourceHeatPump.glb');
