import React, { ReactNode, useCallback } from 'react';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import useEmblaCarousel from 'embla-carousel-react';

const QualityAssuranceCarousel = ({ elements }: { elements: Array<ReactNode> }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'start',
    slidesToScroll: 1,
    containScroll: 'trimSnaps',
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="relative mx-auto max-w-full py-5">
      <div className="-mx-4 w-full overflow-hidden" ref={emblaRef}>
        <div className="flex py-2 pb-8">
          {elements.map((element, index) => (
            <div
              className="relative min-w-0 flex-none px-4 sm:basis-1/2 lg:basis-1/3 2xl:basis-1/4"
              key={`carousel-item-${index}`}
            >
              <div className="bg-primary-darker relative h-full overflow-hidden rounded-2xl drop-shadow-xl">
                {element}
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="embla__prev" onClick={scrollPrev}>
        <MdArrowBackIos size={30} />
      </button>
      <button className="embla__next" onClick={scrollNext}>
        <MdArrowForwardIos size={30} />
      </button>
    </div>
  );
};

export default QualityAssuranceCarousel;
