import React from 'react';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { MdOutlineMail, MdOutlinePhone, MdOutlinePinDrop } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Image } from '@epcbuilder/lib/components';

const Footer = () => {
  return (
    <div className="relative">
      <div className="flex h-3 w-full flex-row">
        <div className="from-secondary-dark via-secondary to-secondary-light h-full flex-1 bg-gradient-to-r" />
        <div className="from-secondary-light via-primary to-primary-dark h-full flex-1 bg-gradient-to-r" />
      </div>
      <footer
        className="text-light flex w-full flex-col items-center justify-between gap-12 bg-[#264653] py-12"
        id="footer"
      >
        <div className="flex w-full max-w-xs flex-col gap-8 px-4 lg:max-w-5xl lg:flex-row">
          <div className="flex w-full flex-col gap-2 lg:order-last">
            <h1 className="text-base lg:text-xl">Stay connected.</h1>
            <div className="flex flex-row gap-2">
              <a href="https://www.facebook.com/TheImproveasyGroup/" target="_blank" rel="noopener noreferrer">
                <div className="bg-neutral flex size-7 items-center justify-center rounded-md p-1 lg:size-8 lg:p-2">
                  <FaFacebookF className="text-[#264653]" />
                </div>
              </a>
              <a href="https://www.linkedin.com/company/improveasy/" target="_blank" rel="noopener noreferrer">
                <div className="bg-neutral flex size-7 items-center justify-center rounded-md p-1 lg:size-8 lg:p-2">
                  <FaLinkedinIn className="text-[#264653]" />
                </div>
              </a>
              <a href="https://twitter.com/ImproveasyGroup" target="_blank" rel="noopener noreferrer">
                <div className="bg-neutral flex size-7 items-center justify-center rounded-md p-1 lg:size-8 lg:p-2">
                  <FaXTwitter className="text-[#264653]" />
                </div>
              </a>
            </div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <h1 className="text-base lg:text-xl">More from EPC Builder.</h1>
            <Link className="text-sm lg:text-base" to="/faq" aria-label="Navigate to FAQ page">
              FAQs
            </Link>
            <Link
              className="whitespace-nowrap text-sm lg:text-base"
              to="/what-to-expect"
              aria-label="Navigate to What To Expect page"
            >
              Energy Assessment - What to expect
            </Link>
            <Link className="text-sm lg:text-base" to="/quick-wins" aria-label="Navigate to Quick wins page">
              Quick Wins
            </Link>
            <Link
              className="text-sm lg:text-base"
              to="/install-measures"
              aria-label="Navigate to Install measures page"
            >
              Home upgrades we offer
            </Link>
            <Link
              className="text-sm lg:text-base"
              to="/home-improvements"
              aria-label="Navigate to Home Improvements page"
            >
              Explore Interactive House
            </Link>
            <Link
              className="text-sm lg:text-base"
              to="/quality-assurance"
              aria-label="Navigate to Quality Assurance page"
            >
              Quality Assurance
            </Link>
            <Link className="text-sm lg:text-base" to="/your-savings" aria-label="Navigate to Your Savings page">
              Your Savings
            </Link>
          </div>
          <div className="flex w-full flex-col gap-2">
            <h1 className="text-base lg:text-xl">Legal stuff.</h1>
            <Link className="text-sm lg:text-base" to="/terms" aria-label="Navigate to Terms & conditions page">
              Terms & Conditions
            </Link>
            <Link className="text-sm lg:text-base" to="/privacy" aria-label="Navigate to Privacy Policy page">
              Privacy Policy
            </Link>
            <Link className="text-sm lg:text-base" to="/refund" aria-label="Navigate to Refund Policy page">
              Assessments Refund Policy
            </Link>
            <Link
              className="text-sm lg:text-base"
              to="/direct-debit-guarantee"
              aria-label="Navigate to Direct Debit Guarantee page"
            >
              Direct Debit Guarantee
            </Link>
            <Link
              className="text-sm lg:text-base"
              to="/finance-and-funding"
              aria-label="Navigate to Finance and Funding page"
            >
              Finance and Funding
            </Link>
          </div>
          <div className="flex w-full flex-col gap-2">
            <h1 className="text-base lg:text-xl">Get in touch.</h1>
            <a href="mailto:info@epcbuilder.com" className="flex flex-row gap-2 ">
              <MdOutlineMail size={20} />
              <p className="text-sm underline lg:text-base">info@epcbuilder.com</p>
            </a>
            <a href="tel:08000584140" className="flex flex-row gap-2">
              <MdOutlinePhone size={20} />
              <p className="text-sm underline lg:text-base">0800 058 4140</p>
            </a>
            <div className="flex flex-row gap-2">
              <MdOutlinePinDrop size={20} />
              <div className="flex flex-col">
                <address className="text-sm not-italic">Station House</address>
                <address className="text-sm not-italic">Stamford New Road</address>
                <address className="text-sm not-italic">Altrincham</address>
                <address className="text-sm not-italic">WA14 1EP</address>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full max-w-xs flex-col items-center px-4 lg:max-w-none lg:flex-row lg:items-end lg:justify-center lg:gap-4">
          <div className="relative mb-2 h-20 w-64 lg:mb-0">
            <Image src="/images/logo-light.png" alt="EPC Builder Logo" className="object-contain" />
          </div>
        </div>
        <div className="text-neutral-dark flex w-full max-w-xs flex-col items-center gap-4 px-4 text-center lg:max-w-none">
          <p className="text-xs">
            © Copyright 2023 Improveasy Ltd | <Link to="/terms">Terms & Conditions</Link> |{' '}
            <Link to="/privacy">Privacy Policy</Link>
          </p>
          <p className="max-w-5xl text-xs">
            EPC Builder is a trading style of Improveasy Ltd, a company registered in England and Wales (Co Reg No
            7807352). Registered Office: Station House, Stamford New Road, Altrincham, Cheshire, WA14 1EP. The firm is
            authorised and regulated by the Financial Conduct Authority (FRN 708623) and offers credit facilities from a
            panel of lenders. A small fee is earned by Improveasy upon activation of a finance plan. The credit is
            subject to application and status.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
