import React from 'react';
import { Helmet } from 'react-helmet';
import FinanceFundingPage from '@/components/finance-funding/FinanaceFundingPage';
import Layout from '@/components/Layout';

const FinanaceFunding = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="Finance and Funding Explained" />
      </Helmet>
      <Layout title="EPC Builder Finanace and Funding">
        <FinanceFundingPage />
      </Layout>
    </>
  );
};

export default FinanaceFunding;
