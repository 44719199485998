import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import QualityAssurancePage from '@/components/quality-assurance/QualityAssurancePage';

const QualityAssurance = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="Our commitment to quality" />
      </Helmet>
      <Layout title="EPC Builder Quality Assurance">
        <QualityAssurancePage />
      </Layout>
    </>
  );
};

export default QualityAssurance;
