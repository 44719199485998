import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, TextInput } from '@epcbuilder/lib/components';
import { AddressResult, PostcodeSearch } from '@epcbuilder/lib/models/properties';
import { getAddressesForPostcode } from '@epcbuilder/lib/network/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import RegisterModal from './RegisterModal';

const postcodeSearchSchema = yup.object().shape({
  postcode: yup.string().required().matches(POSTCODE_REGEX, 'Please enter a valid postcode'),
});

const GetStarted = ({ inline }: { inline?: boolean }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [results, setResults] = useState<AddressResult[]>([]);

  const defaultValues: PostcodeSearch = {
    postcode: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    getValues,
  } = useForm<PostcodeSearch>({
    defaultValues,
    resolver: yupResolver(postcodeSearchSchema),
  });

  const onSubmit: SubmitHandler<PostcodeSearch> = useCallback(
    async (data: PostcodeSearch) => {
      try {
        const response = await getAddressesForPostcode({ postcode: data.postcode.trim() });
        setResults(response);
        setShowModal(true);
      } catch (error: unknown) {
        const { errors, detail } = error as AxiosErrorData;
        handleFormErrors<PostcodeSearch>(setError, errors);

        switch (detail) {
          case 'No addresses could be found':
          case 'Failed getting address':
            toast.error('There was an error, please check your postcode and try again');
            break;
          default:
            handleUnknownDetail(error);
            break;
        }
      }
    },
    [setError]
  );

  return (
    <>
      <form id="postcode-search-form" className="relative flex w-full flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <TextInput
            {...register('postcode')}
            id="Postcode"
            name="postcode"
            title="Your postcode"
            placeholder="Enter postcode..."
            error={errors.postcode?.message}
            className={`${
              inline
                ? 'h-10 rounded-[18px] pl-4 pr-44 lg:h-14 lg:rounded-[40px] lg:pl-8 lg:pr-60'
                : 'h-10 rounded-[18px] px-4 lg:h-14 lg:rounded-[24px]'
            }
              text-dark border-neutral focus:border-primary placeholder:text-neutral-dark w-full border-2 bg-[#fff] pl-4 outline-none`}
            overrideBaseClassnames={true}
          />
        </div>
        <div className={inline ? 'absolute right-1 top-1 lg:right-2 lg:top-2' : 'w-full'}>
          <Button
            className={`${
              inline
                ? 'h-8 rounded-[12px] text-xs lg:h-10 lg:rounded-[30px] lg:text-base'
                : 'h-10 rounded-[18px] text-sm lg:h-14 lg:rounded-[24px] lg:text-xl'
            } font-header flex w-full min-w-fit items-center justify-center rounded-[18px] px-3 uppercase shadow hover:opacity-80 `}
            overrideBaseClassnames={true}
            loading={isSubmitting}
            type="submit"
            id="postcode-search"
          >
            {inline ? 'Get your epc rating' : 'Get your free epc rating now!'}
          </Button>
        </div>
      </form>
      {showModal && (
        <RegisterModal
          postcode={getValues('postcode')}
          initialResults={results}
          onClose={() => setShowModal(false)}
          updateInitialResults={onSubmit}
        />
      )}
    </>
  );
};

export default GetStarted;
