import React from 'react';

export default function CarouselCard({ description, imgSrc }: { description: string; imgSrc: string }) {
  return (
    <div className="flex select-none flex-col">
      <div className="flex h-40 justify-center rounded-t-2xl bg-white p-6">
        <img src={imgSrc} alt="accreditor" className="object-contain" />
      </div>
      <div className="p-6">
        <p className="text-md  text-white md:text-xl">{description}</p>
      </div>
    </div>
  );
}
