import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder - Terms of Service</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Terms of Service">
        <div className="bg-primary flex h-48 w-full items-center justify-center">
          <h1 className="text-light text-center text-2xl">Terms of Service</h1>
        </div>
        <Container>
          <div className="my-8 flex flex-col gap-4">
            <h2>Who we are</h2>
            <p>
              EPC Builder is a service provided by Improveasy Group(Improveasy). Improveasy Group comprises of
              Improveasy Ltd registered in England and Wales (Company Registration number 7807352), Improveasy (ECO) Ltd
              registered in England and Wales (Company Registration number 13976663), Improveasy (GCS) Ltd registered in
              England and Wales (Company Registration number 13976640), Improveasy (Installs) Ltd registered in England
              and Wales (Company Registration number 10833883) and Improveasy (Services) Ltd registered in England and
              Wales (Company Registration number 13976673). Our registered office is Station House, Stamford New Road,
              Altrincham WA14 1EP.
            </p>
            <p>
              When you access, browse, or use this Site you accept, without qualification or limitation, the terms and
              conditions below.
            </p>
            <h2>Your right to use the Site and its contents</h2>
            <p>
              This site is for your personal use only. You may not distribute, exchange, modify, sell, or transmit
              anything you copy from this Site, including but not limited to any text, images, audio,and video, for any
              business, commercial or public purposes. As long as you comply with the terms of these Terms &amp;
              Conditions of Use, Improveasy grants you a non-exclusive, non-transferable limited right to enter and use
              this Site. You agree not to interrupt or attempt to interrupt the operation of this Site in any way.
            </p>
            <h2>Ownership</h2>
            <p>
              All materials on this Site, including but not limited to images, software,and text (the 'Content'), are
              protected by copyright under UK copyright law, international conventions, and other copyright laws. You
              cannot use the Content, except as specified herein. You agree to follow all instructions on this Site
              limiting the way you may use the Content. Any unauthorised use of the Content may violate copyright laws,
              the laws of privacy and publicity, and civil and criminal statutes. If you download any Content from this
              Site, you may not remove any copyright notices or other notices that go with it.
            </p>
            <h2>Our rights to change these Terms &amp; Conditions of Use or Content on the Site</h2>
            <p>
              Improveasy may add to, change,or remove any part of these Terms &amp; Conditions of Use at anytime,
              without notice. Any changes to these Terms Conditions of Use or any terms posted on this Site apply as
              soon as they are posted. By continuing to use this Site after any changes are posted, you are indicating
              your acceptance of those changes. Improveasy may add, change, discontinue, remove,or suspend any other
              Content posted on this Site, including features and specifications of products and/or services described
              or depicted on the Site, temporarily or permanently, at any time, without notice and without liability.
            </p>
            <h2>Indemnification</h2>
            <p>
              You agree to indemnify, defend, and hold Improveasy and all of its directors, employees, associates,
              information providers (collectively, “Indemnified Parties”) harmless from and against all liability and
              costs incurred by the Indemnified Parties in connection with any claims arising out of any breach by you
              of these Terms &amp; Conditions of Use or the foregoing representations, warranties and covenants. You
              will cooperate as fully as reasonably required in our defence of any claim. Improveasy reserves the right,
              at its own expense, to assume the exclusive defence and control of any matter otherwise subject to
              indemnification by you and you shall not, in any event, settle any matter without the written consent of
              Improveasy.
            </p>
            <h2>Disclaimer of Warranties and Damages: Limitation of Liability</h2>
            <p>
              Your use of this site is at your own risk. This site (including all content and functions made available
              on or accessed through this site) is provided “as is.” to the fullest extent permissible by law, with
              regard to the content on this site, Improveasy makes no representations or warranties of any kind
              whatsoever (1) for the accuracy, merchantability, fitness for a particular purpose or non-infringement of
              any content published on or available through the site, (2) that the server that makes this site available
              is free of viruses or other components that may infect, harm or cause damage to your computer equipment or
              any property when you access, browse, download from,or otherwise use the site. Under no circumstances,
              including but not limited to Improveasy negligence, shall Improveasy be liable for any consequential,
              direct, incidental, indirect, punitive or special damages related to (a) the use of, (b) the inability to
              use or (c) errors or omissions in the contents and functions of this site, even if Improveasy or an
              authorised representative thereof has been advised of the possibility of such damages.
            </p>
            <h2>General Provisions</h2>
            <p>
              These Terms &amp; Conditions of Use and any additional terms posted on this Site together constitute the
              entire agreement between Improveasy and you with respect to your use of this Site. Any cause of action you
              may have with respect to your use of the Site must be commenced within one (1) year after the claim of
              action arises. If for any reason a court of competent jurisdiction finds any provision of the Terms &amp;
              Conditions of Use, or portion thereof, to be unenforceable, that provision shall be enforced to the
              maximum extent permissible so as to affect the intent of this agreement, and the remainder of these Terms
              &amp; Conditions of Use shall continue in full force and effect. These Terms &amp; conditions of Use shall
              be governed by and construed in accordance with the laws of the United Kingdom.
            </p>
            <h2>Privacy Policy</h2>
            <p>
              Improveasy takes the privacy of its users seriously. We are committed to safeguarding the privacy of our
              users while providing a personalised and valuable service. Our Privacy Policy statement explains the data
              processing practices of Improveasy. If you have any requests concerning your personal information or any
              queries regarding these practices, please contact us by email.
            </p>
            <h2>Information</h2>
            <p>
              Personal information is collected by Improveasy. We collect personal information from you through the use
              of enquiry and registration forms and every time you e-mail us your details. We also collect information
              automatically about your visit to our site. The information obtained in this way, which includes
              demographic data and browsing patterns, is only used in aggregate form.
            </p>
            <h2>Use and Disclosure of personal information</h2>
            <p>We process personal information collected via the Improveasy site for the purposes of:</p>
            <ul className="ml-8">
              <li className="list-disc text-base">providing a personalised service</li>
              <li className="list-disc text-base">conducting market research surveys</li>
              <li className="list-disc text-base">running competitions</li>
              <li className="list-disc text-base">
                providing you with information about products and services we offer.
              </li>
            </ul>
            <p>
              If you do not wish to receive information about our products or services, please untick the appropriate
              box on any form you submit. We may also disclose your information to business partners and third-party
              suppliers we engage to provide services which involve processing data on our behalf, successors in title
              to our business or in accordance with a properly executed court order.
            </p>
            <p>We also use information in aggregate form (so that no individual user is identified):</p>
            <ul className="ml-8">
              <li className="list-disc text-base">to build up marketing profiles</li>
              <li className="list-disc text-base">to aid strategic development</li>
              <li className="list-disc text-base">to manage our relationship with advertisers</li>
              <li className="list-disc text-base">to audit usage of the site.</li>
            </ul>
            <h2>Links to Other Sites</h2>
            <p>
              EPC Builder contains links to other sites. Please be aware that Improveasy is not responsible for the
              privacy practices or content of such other sites. This privacy statement applies solely to information
              collected by this website.
            </p>
            <h2>Internet-based transfers</h2>
            <p>
              Given that the Internet is a global environment, using the Internet to collect and process personal data
              necessarily involves the transmission of data on an international basis. Therefore, by browsing the EPC
              Builder site and communicating electronically with us you acknowledge and agree to our processing of
              personal data in this way.
            </p>
            <h2>Registration</h2>
            <p>
              It is your responsibility to ensure that the details provided by you on registration or at any time are
              correct and complete.
              <br />
              It is your responsibility to inform us of any changes to the information that you provide when registering
              by updating your personal details to ensure we can communicate with you effectively.
              <br />
              We may suspend or cancel your registration with immediate effect for any reasonable purposes or if you
              breach these terms and conditions.
              <br />
              You may cancel your registration at any time by informing us in writing toImproveasyGroup (EPC Builder),
              Station House, Stamford New Road, Altrincham, Cheshire, WA14 1EP. Cancellation or suspension of your
              registration does not affect any statutory rights.
            </p>
            <h2>Password and Security</h2>
            <p>
              When you register on EPC Builder, you will be asked to create a password,which you should keep
              confidential and not disclose or share with anyone.
              <br />
              If we have reason to believe that there is or is likely to be any misuse of the EPC Builder or breach of
              security, we may require you to change your password or suspend your account.
            </p>
            <h2>How to contact us</h2>
            <p>
              If you wish to contact us,including with complaints you can write to us at Improveasy Group (EPC Builder),
              Station House, Stamford New Road, Altrincham, England, WA14 1EP or email us at{' '}
              <a href="mailto:support@epcbuilder.com" className="text-primary underline">
                support@epcbuilder.com
              </a>
              .
            </p>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Terms;
