import React from 'react';
import PageSection from '../PageSection';

const WhatToExpectPage = () => {
  return (
    <div className="mx-4 my-7 flex min-h-screen flex-col gap-4 sm:mx-8 sm:my-6 sm:gap-6 md:mx-6 md:my-12 md:gap-8 lg:gap-10 xl:mx-auto xl:w-11/12 xl:max-w-screen-2xl xl:gap-12">
      <div id="finance-intro" className="flex flex-col gap-2 md:gap-4 xl:gap-6">
        <h2 className="text-dark text-md text-2xl font-bold leading-tight md:text-[48px] xl:text-[64px]">
          Energy Assessment - What To Expect
        </h2>
        <p className="text-base leading-7 md:text-xl md:leading-8 xl:text-2xl xl:leading-9">
          The assessment will typically last 1 - 2 hours depending on the size of your property.
        </p>
      </div>
      <PageSection title="The Assessment Will Include:">
        <div className="flex flex-col gap-4 xl:gap-6">
          <ul className="space-y-6">
            <li className="flex flex-col gap-2">
              <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Building Fabric</h3>
              <p className="xl:text-lg">
                The assessor examines the construction materials and the condition of walls, floors, roofs, windows, and
                doors.
              </p>
            </li>
            <li className="flex flex-col gap-2">
              <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Heating System</h3>
              <p className="xl:text-lg">
                Information on the type, age, and efficiency of boilers, radiators, underfloor heating, and thermostats
                is collected.
              </p>
            </li>
            <li className="flex flex-col gap-2">
              <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Insulation</h3>
              <p className="xl:text-lg">
                The assessor checks for insulation in walls, roofs, and floors. They will require access to the loft to
                assess if there is any insulation present and if so, what depth it is.
              </p>
            </li>
            <li className="flex flex-col gap-2">
              <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Hot Water System</h3>
              <p className="xl:text-lg">The type, efficiency, and controls of the hot water system are assessed.</p>
            </li>
            <li className="flex flex-col gap-2">
              <h3 className="text-xl font-medium md:text-[22px] xl:text-2xl">Lighting</h3>
              <p className="xl:text-lg">The type and efficiency of lighting installed in the property are evaluated.</p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection title="During The Process The Assessor Will:">
        <ul className="list-inside list-disc space-y-4">
          <li className="xl:text-lg">
            Take various measurements, such as room sizes, wall thickness, and window dimensions, this is to calculate
            heat loss.
          </li>
          <li className="xl:text-lg">
            Record all details related to the building's energy use and efficiency. This includes the age and the the
            property, heating and cooling systems, insulation levels, and energy-saving features.
          </li>
          <li className="xl:text-lg">Take photographs as part of the documentation process.</li>
        </ul>
      </PageSection>
    </div>
  );
};

export default WhatToExpectPage;
