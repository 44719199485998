import React from 'react';
import { InteractiveHouse } from '../interactive-house';
import GetStarted from '../interactive-house/GetStarted';

const HomeImprovements = () => {
  return (
    <>
      <div className="relative flex flex-col items-center gap-8 overflow-hidden bg-white">
        <div className="absolute left-1/2 top-1/2 size-[500px] -translate-x-1/2 -translate-y-1/2" />
        <div className="items-left z-10 flex w-full flex-col justify-center gap-6 px-6 pt-11 lg:max-w-none xl:px-52">
          <h1 className="max-w-[600px] text-[24px] md:text-[48px] md:leading-[60px] xl:text-[64px] xl:leading-[80px] ">
            Home improvement recommendations
          </h1>
          <p className="text-[16px] leading-[28px] md:mb-12 md:text-[20px] lg:leading-[38px] xl:text-[24px]">
            Our on-site assessment will ensure we only recommend improvements which are suitable for your property
          </p>
        </div>
        <div className="z-10 w-full bg-[#F8F9FA] py-12">
          <InteractiveHouse />
        </div>
      </div>
      <div className="flex w-full flex-col items-center gap-2 bg-white p-2 lg:gap-4 lg:p-4">
        <h1 className="mt-12 sm:w-[548px]">Ready to get started?</h1>
        <div className="mb-12 flex w-[548px] flex-row gap-2">
          <GetStarted />
        </div>
      </div>
    </>
  );
};

export default HomeImprovements;
