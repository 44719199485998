import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';

const DirectDebitGuarantee = () => {
  const PageSection = ({ title, children }: { title: string; children: ReactNode }) => {
    return (
      <div className="flex flex-col gap-1">
        <h2>{title}</h2>
        <div className="flex flex-col gap-2">{children}</div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>EPC Builder - Direct Debit Guarantee</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Direct Debit Guarantee">
        <div className="bg-primary flex h-48 w-full items-center justify-center">
          <h1 className="text-light text-center text-2xl">Direct Debit Guarantee</h1>
        </div>
        <Container>
          <div className="my-8 flex flex-col gap-6">
            <PageSection title="">
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-bold">Direct Debit Guarantee</h2>
                <img src="/images/direct-debit-logo.png" alt="Direct Debit Logo" className="ml-6 h-[60px]" />
              </div>
              <ul className="ml-8 mt-4">
                <li className="list-disc text-base">
                  This Guarantee is offered by all banks and building societies that accept instructions to pay Direct
                  Debits.
                </li>
                <li className="list-disc text-base">
                  If there are any changes to the amount, date or frequency of your Direct Debit GoCardless will notify
                  you 3 working days in advance of your account being debited or as otherwise agreed. If you request
                  GoCardless to collect a payment, confirmation of the amount and date will be given to you at the time
                  of the request.
                </li>
                <li className="list-disc text-base">
                  If an error is made in the payment of your Direct Debit, by GoCardless or your bank or building
                  society, you are entitled to a full and immediate refund of the amount paid from your bank or building
                  society - if you receive a refund you are not entitled to, you must pay it back when GoCardless asks
                  you to.
                </li>
                <li className="list-disc text-base">
                  You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written
                  confirmation may be required. Please also notify us.
                </li>
              </ul>
            </PageSection>

            <PageSection title="How to claim a refund under the Direct Debit Guarantee">
              <p className="mt-4">
                If a Direct Debit payment has been taken in error you will be entitled to a full and immediate refund
                from your bank. To claim your refund:
              </p>
              <ul className="ml-8">
                <li className="list-disc text-base">
                  Contact your bank in writing or over the phone to ask for a refund.
                </li>
                <li className="list-disc text-base">
                  If they fail to give you a refund, contact them again in writing, escalating to your Branch Manager or
                  Customer Service Manager and referring directly to the Direct Debit Guarantee.
                </li>
              </ul>
              <PageSection title="How to cancel a Direct Debit:">
                <ul className="ml-8">
                  <li>
                    <p className="mt-4 font-bold">1. How do you cancel a Direct Debit?</p>
                    <p className="ml-4 mt-2">
                      <span className="font-bold">
                        You just need to let either the person or organisation you are paying or your bank know
                      </span>{' '}
                      that you want to cancel your Direct Debit. You can do this in writing or electronically or orally.
                      However, if you do cancel using the phone or internet, you may also need to give written
                      confirmation.
                      <p className="mt-4">
                        We’d recommend speaking to both your bank and the organisation concerned. You could do this by
                        sending the organisation a copy of the letter you send to your bank.
                      </p>
                      <p className="mt-4">
                        <span className="font-bold">Remember:</span> Cancelling a Direct Debit simply stops payments
                        from going to the organisation you are paying. If you carry on receiving the goods or service or
                        if you have a contract then you will need to set up an alternative payment method.
                      </p>
                    </p>
                  </li>
                  <li>
                    <p className="mt-4 font-bold">2. When can you cancel a Direct Debit?</p>
                    <p className="ml-4 mt-2">
                      <span className="font-bold">You can cancel a Direct Debit at any time.</span>
                      <p className="mt-4">
                        Your bank or building society will generally need at least one day's notice before the Direct
                        Debit is due to be paid. Find out how much time your bank needs to process a cancellation and
                        try not to leave it until the last minute or the payment may still be made.
                      </p>
                    </p>
                  </li>
                  <li>
                    <p className="mt-4 font-bold">
                      3. What information do you need to include to cancel a Direct Debit?
                    </p>
                    <p className="ml-4 mt-2">Make sure you include:</p>
                    <ul className="ml-8">
                      <li className="list-disc text-base">The name of the organisation you’re paying.</li>
                      <li className="list-disc text-base">
                        The name(s) of the account holder(s) for your bank account.
                      </li>
                      <li className="list-disc text-base">Your branch sort code.</li>
                      <li className="list-disc text-base">Your bank or building society account number.</li>
                      <li className="list-disc text-base">Your reference with the organization (if known).</li>
                    </ul>
                    <p className="mt-4">
                      The more information you can give, the easier it will be for your bank to action, so if you know
                      the amount and payment date include this too.
                    </p>
                  </li>
                </ul>
              </PageSection>
              <PageSection title="What if the Direct Debit is not cancelled?">
                <p className="mt-4">
                  Once you’ve cancelled a Direct Debit take care to check your bank statements going forwards to ensure
                  your instruction has been followed.
                </p>
                <p className="mt-4">
                  If your bank doesn’t cancel your Direct Debit and a payment is taken after you’ve asked for it to be
                  cancelled, you’ll be entitled to an immediate refund from your bank under the Direct Debit Guarantee.
                </p>
              </PageSection>
            </PageSection>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default DirectDebitGuarantee;
